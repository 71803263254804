import { API_URL } from "Config";

const restoreLocations = async (search: string) => {
  try {
    if (typeof search !== "string") {
      throw new Error(`restoreLocations.error - search string required`);
    }
    const response = await fetch(`${API_URL}v4/locations?search=${search}`);
    if (response.ok) {
      const json = await response.json();
      return json.locations;
    }
    // eslint-disable-next-line
    console.error("restoreLocations error - response not ok");
  } catch (err) {
    // eslint-disable-next-line
    console.error("restoreLocations error", err);
  }
  return [];
};
export default restoreLocations;
