import { SEARCH_PROPERTY_QUERY } from "Constants/actionTypes";

const initialState = {
  error: false,
  errorCode: 0,
  errorMessage: "",
  pending: false,
  propertyList: [],
  minPrice: null,
  maxPrice: null,
  success: false,
  totalPages: 1,
  totalCount: 0,
};

export function propertyListSearch(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case SEARCH_PROPERTY_QUERY.SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        minPrice: action.minPrice,
        maxPrice: action.maxPrice,
        propertyList: action.propertyList,
        totalPages: action.totalPages,
        totalCount: action.totalCount,
      };
    case SEARCH_PROPERTY_QUERY.PENDING:
      return { ...state, pending: action.status };
    case SEARCH_PROPERTY_QUERY.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
        pending: false,
      };
    case SEARCH_PROPERTY_QUERY.RESET:
      return initialState;
    default:
      return state;
  }
}
