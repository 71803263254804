import { logEvent } from "ThirdParty";

// unexpected error
const DISASTER = "UNKNOWN";

// handled HTTP codes
export const OK = 200;

export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const CONFLICT = 409;
export const PRECONDITION_FAILED = 412;
export const UNPROCESSABLE_ENTITY = 422;

export const INTERNAL_SERVER_ERROR = 500;

/**
 * NetworkError
 * throw a NetworkError if response.ok is not truthy
 * deliver meaningful error messages caused network requests with esponse.status that isn't 2*
 */

export class NetworkError extends Error {
  status: number
  statusText: string

  constructor({ status, statusText }: { status: number, statusText: string }) {
    super(statusText);
    this.name = "NetworkError";
    this.status = status;
    this.statusText = statusText;
  }
}

export interface HandledErrors {
  errorCodes: Number[]
}

/**
 * HandledError
 * throw a HandledError if response.status is an expected error code
 * for any expected NetworkError make it a HandledError
 * relies on the API to send meaningful errors for views to display
 */

export class HandledError extends Error {
  errorMessage: string
  errorCode: string
  errorDetails: string
  errorTitle: string
  meta?: { [key: string]: any }

  constructor(err: { errorMessage: string, errorCode: string, errorDetails?: string, errorTitle?: string, meta?: { [key: string]: any } }) {
    super(err.errorMessage);
    this.errorMessage = err.errorMessage;
    this.errorCode = err.errorCode;
    this.errorDetails = err.errorDetails || "";
    this.errorTitle = err.errorTitle || "";
    this.meta = err?.meta;
  }
}

export interface FormattedError {
  type: string
  errorMessage: string
  errorCode: string
  errorDetails?: string
  errorTitle?: string
  meta?: { [key: string]: any }
}

/**
 * generateErrorHandler
 * returns a function to handle serialising of error messages
 * handles expected and unexpected status codes
 */

export const generateErrorHandler = (errorType: string) => {
  return (err: any = {}, sendToSentry: boolean = true): FormattedError => {
    if (err instanceof HandledError) {
      return {
        type: errorType,
        errorCode: err.errorCode,
        errorDetails: err.errorDetails,
        errorTitle: err.errorTitle,
        errorMessage: err.errorMessage,
        meta: err?.meta,
      };
    }

    let formattedError = null;
    if (err instanceof NetworkError) {
      formattedError = {
        type: errorType,
        errorCode: err.status.toString(),
        errorMessage: err.statusText,
      };
    }

    if (formattedError === null) {
      formattedError = {
        type: errorType,
        errorCode: DISASTER,
        errorMessage: DISASTER,
      };
    }

    // eslint-disable-next-line
    console.warn(errorType, formattedError);
    // log a custom event to sentry - do not log exception, the err is a `NetworkError` and can not be inspected by sentry
    sendToSentry &&
      logEvent(
        { message: `NetworkError: ${errorType}`, stacktrace: err.stack },
        err,
      );
    return formattedError;
  };
};

/**
 * extractHandledError
 * for expected errors we should get a decent response matching jsonapi schema
 * errorCodes: array of handled error codes
 */

export const extractHandledError = async (response: Response, { errorCodes }: HandledErrors) => {
  const errorCode = response.status.toString();
  if (errorCodes.includes(response.status)) {
    let errorMessage = "UNKNOWN";
    let errorDetails = "";
    let errorTitle = "";
    let meta = null;
    try {
      const json = await response.json();
      errorMessage = json.errors.map(({ detail }: { detail: string }) => detail).join(", ");
      errorTitle = json.errors.map(({ title }: { title: string }) => title).join(", ");
      errorDetails = json.errors
        .map(({ source }: { source: { parameter: string } }) => source?.parameter)
        .filter(Boolean)
        .join(", ");
      meta = json?.meta;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(
        "extractHandledError could not get a json error message from API response",
        err,
      );
    }
    throw new HandledError({
      errorCode,
      errorDetails,
      errorMessage,
      errorTitle,
      meta,
    });
  }
};
