const toRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180.0;
};

const toDegrees = (radians: number) => {
    return (radians * 180.0) / Math.PI;
};
/**
 * Get the coordinates of a ending point from a starting point by distance and map bearing
 * @param Point point The startng point
 * @param number bearing Direction angle
 * @param number distanceKm Distance in km
 */
interface Point {
    lat: number;
    lng: number;
}
export const getPointByDistanceAndBearing = (point: Point, bearing: number, distanceKm: number) => {
    const earthRadius = 6378.1;
    const bearingR = toRadians(bearing);
    const latR = toRadians(point.lat);
    const lngR = toRadians(point.lng);

    const distanceToRadius = distanceKm / earthRadius;

    const newLatR = Math.asin(
      Math.sin(latR) * Math.cos(distanceToRadius) +
        Math.cos(latR) * Math.sin(distanceToRadius) * Math.cos(bearingR),
    );
    const newLngR =
      lngR +
      Math.atan2(
        Math.sin(bearingR) * Math.sin(distanceToRadius) * Math.cos(latR),
        Math.cos(distanceToRadius) - Math.sin(latR) * Math.sin(newLatR),
      );

    return { lat: toDegrees(newLatR), lng: toDegrees(newLngR) };
};
