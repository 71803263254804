import React, { PureComponent } from "react";
import isEmail from "validator/lib/isEmail";

import {
  Alert,
  ALERT_ERROR,
  Button,
  TYPE_PASSWORD,
  TYPE_EMAIL,
  InputText,
  BUTTON_CTA,
} from "@madecomfy/webooi";

import LinkButton from "./components/LinkButton";
import logoInverse from "./assets/logoInverse.svg";
import Verify from "Containers/Settings/components/Verify";
import { H2, Body } from "Components/Typography";
import {
  INTERNAL_SERVER_ERROR,
  PRECONDITION_FAILED,
  UNAUTHORIZED,
} from "Utils/Network";
import { FormWrapper, LogoInv, BodyWrapper } from "./components/Elements";

const errorMessages = {
  [UNAUTHORIZED]: "Invalid email address or password. Please try again.",
  [INTERNAL_SERVER_ERROR]:
    "Server error. Please contact support@madecomfy.com.au",
};

// this component handles state internally rather than in webooi due to custom 'submitted' check
interface IProps {
  login: (...args: any) => any;
  resetLogin: (...args: any) => any;
  sessionStatus: { [key: string]: any };
  history: { [key: string]: any };
}
class WelcomeLogin extends PureComponent<IProps> {
  state = {
    email: "",
    isValidEmail: false,
    isValidPassword: false,
    password: "",
    showVerify: false,
    submitted: 0,
    otp: "",
  };

  componentDidMount() {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    // Extract email and password from the URL
    let email = params?.get("email");
    if (email) {
      email = email.replace(/ /g, "+");
    }
    const password = params.get("password");
    this.setState({ email: email || "", password: password || "" });
  }
  componentDidUpdate() {
    const {
      history,
      sessionStatus: { user },
    } = this.props;
    if (user) {
      // post log in: go to root, or?
      const values = new URLSearchParams(document.location.search);
      const location = values.get("pl") || "/";
      history.push(location);
    }
    if (this.props?.sessionStatus?.errorCode && !this.state.showVerify) {
      this.setState({ showVerify: true });
    }
  }

  handleSubmit = async (event?: any) => {
    event && event.preventDefault();

    const { email, password, submitted, otp } = this.state;
    const isValidEmail = isEmail(email);
    const isValidPassword = Boolean(password && password.length >= 8);

    this.setState({
      email,
      isValidEmail,
      isValidPassword,
      password,
      submitted: submitted + 1,
    });

    const isValid = isValidEmail && isValidPassword;
    if (!isValid) return;
    if (otp) {
      this.props.login(email, password, otp); // Pass otp if it's available
    } else {
      this.props.login(email, password); // Call without otp if there's no value
    }
  };
  render() {
    const {
      email,
      password,
      isValidEmail,
      isValidPassword,
      otp,
      showVerify,
      submitted,
    } = this.state;
    const {
      resetLogin,
      sessionStatus: { errorCode, pending },
    } = this.props;
    return ((errorCode === UNAUTHORIZED || errorCode === "401") &&
      otp &&
      showVerify) ||
      ((errorCode === PRECONDITION_FAILED || errorCode === "412") &&
        showVerify) ? (
      <>
        <Verify
          buttonLabel="Back to login"
          error={errorCode}
          handleOtp={(otp) => {
            this.setState({ otp }, () => {
              // Call handleSubmit after otp is set
              this.handleSubmit();
            });
          }}
          handleShowVerify={() => {
            this.setState({
              showVerify: false,
              otp: "",
            });
            resetLogin();
          }}
        />
      </>
    ) : (
      <div>
        <LogoInv src={logoInverse} />
        <H2 white>Welcome to MadeComfy</H2>
        <BodyWrapper>
          <Body color="#fff">Please log in to continue</Body>
        </BodyWrapper>
        <FormWrapper>
          {errorCode ? (
            <Alert
              message={
                errorMessages[errorCode as keyof typeof errorMessages] ||
                errorCode
              }
              type={ALERT_ERROR}
            />
          ) : null}
          <form onSubmit={this.handleSubmit}>
            <InputText
              label="Email address"
              icon="mail"
              name="userEmail"
              placeholder="Enter your email"
              testId="email"
              type={TYPE_EMAIL}
              errorServer={
                submitted === 0 || isValidEmail
                  ? ""
                  : ("Enter a valid email" as any)
              }
              sendValue={({ value }: any) => {
                const isValidEmail = submitted === 0 || isEmail(value);
                this.setState({ email: value, isValidEmail });
              }}
              value={email || ""}
            />
            <InputText
              label="Password"
              icon="lock"
              name="userPassword"
              placeholder="Enter your password"
              testId="password"
              type={TYPE_PASSWORD}
              errorServer={
                submitted === 0 || isValidPassword
                  ? ""
                  : ("The password must be at least 8 characters long" as any)
              }
              sendValue={({ value }: any) => {
                const isValidPassword =
                  submitted === 0 || Boolean(value && value.length >= 8);
                this.setState({ password: value, isValidPassword });
              }}
              value={password || ""}
            />
            <Button
              label="Login"
              isLoading={pending}
              type={BUTTON_CTA}
              onClick={this.handleSubmit}
            />
          </form>
          <LinkButton
            testId="welcome-forgot"
            to="/forgot"
            label="Forgot your password?"
          />
        </FormWrapper>
      </div>
    );
  }
}

export default WelcomeLogin;
