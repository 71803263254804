import debounce from "lodash/debounce";
import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import {
  Alert,
  ALERT_ERROR,
  Button,
  BUTTON_INLINE,
  BUTTON_PRIMARY,
  CheckBox,
  InputText,
  TYPE_TEXT,
} from "@madecomfy/webooi";

import closeCross from "./components/assets/closeCross.svg";
import Loading from "Components/Loading";
import NoProperty from "./components/NoProperty";
import PropertyTable from "./components/PropertyTable";
import {
  SearchAvailability,
  WrapperFlex,
} from "./components/SearchAvailability";
import withClickOutside from "HOC/withClickOutside";
import { Body } from "Components/Typography";
import { Filter } from "./components/Filter";
import { formsRestore } from "Actions/resources";
import { isAuthorized } from "Utils/methods/authentication";
import { ROLE } from "Constants/userRoles";
import { RouteComponentProps, withRouter } from "react-router";
import { Sort } from "./components/Sort";
import { WithSessionCheckProps } from "HOC/withSessionCheck";
import { useHistory } from "react-router-dom";
import withURLQuery from "HOC/withURLQuery";

interface IProps extends RouteComponentProps, WithSessionCheckProps {
  autoFocus?: boolean;
  clearCache?: boolean;
  floating?: boolean;
  handleClick?: (...args: any) => any;
  isDisplayed?: boolean; // from withClickOutside
  lazyLoad?: boolean;
  limit?: number;
  onClickOutside?: (...args: any) => any; // from withClickOutside
  onClose?: (...args: any) => any;
  propertiesRestore: (...args: any) => any;
  propertyListSearch?: any; // FIXME: for some reason {[key: string]: any} throws an error
  resetPropertiesRestore: (...args: any) => any;
  showButtons?: boolean;
  user?: any; // FIXME: for some reason {[key: string]: any} throws an error
  filterText?: string;
  sort?: string;
  minPrice?: string;
  maxPrice?: string;
  availableFrom?: string;
  availableTo?: string;
}

const PropertyListWrapper = styled.div<any>`
  padding: 0;
  ${({ floating, mounted }) => {
    if (floating) {
      // floating is version on dashboard: `/properties/{code}` - invididual property
      return `
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        height: ${mounted ? 450 : 0}px;
        overflow: hidden;
        position: absolute;
        transition: height 0.25s ease-in-out;
        width: 100%;
        z-index: 10;
        background: #fff;
        border-radius: 4px;
        &:before {
          background-image: linear-gradient(to bottom, rgba(255,255,255,0) 70%, rgba(255,255,255,1) 100%);
          bottom: 0;
          content: '';
          left: 0;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 0;
        }
      `;
    }
    // otherwise version on `/properties` - properties list
  }};
  div[data-test="loading"] {
    bottom: 100px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 750px) {
    display: block;
    width: 100%;
  }
`;

const WrapperSearch = styled.div<any>`
  flex: 1 0 0;
  position: relative;
  @media (min-width: ${({ theme }) => theme.minTablet}) {
    input {
      width: ${({ floating }) => (floating ? "100%" : "445px")};
    }
  }
`;

const CloseCross = styled.img`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 19px;
  transition: transform 0.15s ease-in-out;
  z-index: 11;
  &:hover {
    transform: scale(1.2);
  }
`;
const Header = styled.p<any>`
  font-family: InterBold;
  font-size: 28px;
  color: #303336;
  margin: 24px 24px 16px 24px;
  display: ${({ lazyLoad }) => (lazyLoad ? "block" : "none")};
`;
const WrapperFilter = styled.div<any>`
  flex: 0 0 170px;
  > div {
    margin: 14px 0 0 12px;
  }
  @media only screen and (max-width: 750px) {
    > div {
      margin: 16px 0 0 0;
    }
    display: flex;
  }
`;
const Wrapper = styled.div<{ isOwner: boolean }>`
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 24px;
  display: ${({ isOwner }) => (isOwner ? "inline-flex" : "flex")};
  @media only screen and (max-width: 1275px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const FlexWrapper = styled(WrapperFlex)<{ isOwner: boolean }>`
  @media only screen and (min-width: 975px) {
    margin-top: ${({ isOwner }) => (isOwner ? "-69px" : "0")};
  }
`;
const Count = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #939296;
  margin: 0;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  align-items: center;
  div {
    margin: 0;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
`;

const PropertyList: React.FC<IProps> = (props) => {
  const { lazyLoad = true } = props;
  const history = useHistory();
  const [filterText, setFilterText] = useState(props.filterText || "");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(props.autoFocus || false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(props?.limit || 25);
  const [resetList, setResetList] = useState(false);
  const [firstInitial, setFirstInitial] = useState(true);
  const [propertyStatus, setPropertyStatus] = useState(
    lazyLoad ? "active" : "onboarding,active,offboarding",
  );
  const [sort, setSort] = useState(props.sort || "");
  const [filter, setFilter] = useState("");
  const [filterAvailability, setFilterAvailability] = useState("");
  const [queryAvailability, setQueryAvailability] = useState("");
  const [clearSearch, setClearSearch] = useState(false);
  const [showCorporate, setShowCorporate] = useState(false);
  const [flag, setFlag] = useState(false);
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [formUrl, setFormUrl] = useState("");
  const [filterPriceRange, setFilterPriceRange] = useState("");
  const [selectedMinPrice, setSelectedMinPrice] = useState(
    props.minPrice || "",
  );
  const [selectedMaxPrice, setSelectedMaxPrice] = useState(
    props.maxPrice || "",
  );
  const loader = useRef(null);

  const filterSearch = (filterText: string) => {
    setFlag(true);
    setResetList(true);
    setFilterText(filterText);
    setPageNumber(1);
    if (filterText.trim() === "" && filterText !== "") return;
    debouncedFetchData(
      filterText,
      propertyStatus,
      pageNumber,
      sort,
      filter,
      filterAvailability,
      filterPriceRange,
    );
  };

  const fetchData = async (
    filterText?: string,
    propertyStatus?: string,
    pageNum?: number,
    sort?: string,
    filter?: string,
    filterAvailability?: string,
    filterPriceRange?: string,
  ) => {
    setLoading(true);
    const isStatusAvailable = filter?.includes("propertyStatuses");
    if (filterText) {
      setFilterAvailability("");
    }
    try {
      await props.propertiesRestore({
        query: filterText,
        pageNumber: pageNum || pageNumber,
        pageSize: pageSize,
        ...(!isStatusAvailable && { propertyStatus }),
        sort,
        filter,
        ...(filterAvailability && !filterText ? { filterAvailability } : {}),
        filterPriceRange,
      });
      if (pageNum) setPageNumber(pageNum);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line
      setLoading(false);
    }
  };
  const debouncedFetchData = useCallback(
    debounce(
      (
        filterText?: string,
        propertyStatus?: string,
        pageNum?: number,
        sort?: string,
        filter?: string,
        filterAvailability?: string,
        filterPriceRange?: string,
      ) => {
        fetchData(
          filterText,
          propertyStatus,
          pageNum,
          sort,
          filter,
          filterAvailability,
          filterPriceRange,
        );
      },
      600,
      {
        leading: false,
        trailing: true,
      },
    ),
    [
      props?.propertyListSearch?.totalPages,
      props?.limit,
      pageNumber,
      sort,
      filter,
      filterAvailability,
      filterPriceRange,
    ],
  );
  const resetProperties = async () => {
    const { resetPropertiesRestore } = props;
    await resetPropertiesRestore();
  };
  const prevFilter = useRef<string | null>(null);
  const prevFilterAvailability = useRef<string | null>(null);
  const prevSort = useRef<string | null>(null);
  const prevFilterPriceRange = useRef<string | null>(null);

  useEffect(() => {
    setPageSize(props?.limit || 25);
    const { autoFocus = true, clearCache } = props;
    if (clearCache) {
      resetProperties();
    }
    if (autoFocus === false) return;
    if (flag) return;
    if (firstInitial) setFirstInitial(false);
    setResetList(true);
    let pageNum = pageNumber;
    if (
      filter !== prevFilter.current ||
      sort !== prevSort.current ||
      filterAvailability !== prevFilterAvailability.current ||
      filterPriceRange !== prevFilterPriceRange.current
    ) {
      pageNum = 1;
    }
    debouncedFetchData(
      filterText,
      propertyStatus,
      pageNum,
      sort,
      filter,
      filterAvailability,
      filterPriceRange,
    );
    setTimeout(() => {
      setMounted(true);
      const input = document.querySelector(
        "input[data-test='input-text-property-search']",
      ) as any;
      // extra guarded to not invadvertantly cause a browser compatibility error
      input && input.focus && input.focus();
    }, 1);
    prevFilter.current = filter;
    prevFilterAvailability.current = filterAvailability;
    prevFilterPriceRange.current = filterPriceRange;
    prevSort.current = sort;
  }, [propertyStatus, sort, filter, filterAvailability, filterPriceRange]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.5,
    };

    const handleObserver = (entries: any) => {
      const target = entries[0];
      if (target.isIntersecting && !loading && items?.length >= pageSize) {
        setResetList(false);
        const { propertyListSearch } = props;
        const nextPage = pageNumber + 1;
        if (nextPage > propertyListSearch.totalPages) return;
        debouncedFetchData(
          filterText,
          propertyStatus,
          nextPage,
          sort,
          filter,
          filterAvailability,
          filterPriceRange,
        );
      }
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loading]);
  useEffect(() => {
    setItems((prevItems) =>
      resetList
        ? props.propertyListSearch?.propertyList
        : ([...prevItems, ...props.propertyListSearch?.propertyList] as any),
    );
  }, [props.propertyListSearch?.propertyList]);
  const {
    propertyListSearch,
    floating = false,
    showButtons = true,
    user: {
      roles,
      propertyStatus: { count },
    },
    //  lazyLoad = true,
  } = props;
  const renderEmpty = () => {
    return <NoProperty />;
  };
  if (
    !isAuthorized([ROLE.STAFF, ROLE.ADMIN], roles) // let staff and admins see all properties
  ) {
    if (count === 0) {
      // user is onboarding, or could be offboarded!
      return renderEmpty();
    }
  }

  const {
    connectionError,
    error,
    pending,
    propertyList,
    success,
    totalCount,
    minPrice,
    maxPrice,
  } = propertyListSearch;

  const renderSearch = () => {
    const { floating, onClose } = props;
    return (
      <WrapperSearch floating={floating}>
        <InputText
          my={0}
          name="propertiesRestore"
          placeholder="Search property"
          sendValue={({ value }: any) => {
            setClearSearch(false);
            filterSearch(value);
          }}
          testId="property-search"
          type={TYPE_TEXT}
          value={clearSearch ? "" : filterText}
        />
        {floating && <CloseCross src={closeCross} onClick={onClose} />}
      </WrapperSearch>
    );
  };
  const renderError = () => {
    const { errorCode, errorMessage } = props.propertyListSearch;
    return (
      <SearchWrapper>
        <Alert
          message={`There was an error ${
            (errorCode || "") + " " + (errorMessage || "")
          }`}
          testId="property-list"
          title="Could not load properties"
          type={ALERT_ERROR}
        />
      </SearchWrapper>
    );
  };
  const renderInActive = () => {
    if (!isAuthorized([ROLE.STAFF, ROLE.ADMIN, ROLE.CONTENT_CREATOR], roles)) {
      return null;
    }
    return (
      <WrapperFilter>
        <CheckBox
          name="searchInactive"
          options={[
            {
              value: "includeInactive",
              label: "Include Inactive",
            },
          ]}
          sendValue={({ value }: any) => {
            setPageNumber(1);
            if (value) {
              setPropertyStatus((prevStatus) => `${prevStatus},inactive`);
              return;
            }
            setPropertyStatus((prevStatus) =>
              prevStatus.replace(",inactive", ""),
            );
          }}
          testId="include-in-results"
        />
      </WrapperFilter>
    );
  };
  const isDateRange =
    filterAvailability?.includes("availableFrom") &&
    filterAvailability.includes("availableTo");
  const isLicensee = isAuthorized([ROLE.LICENSEE], roles);
  useEffect(() => {
    if (isLicensee) {
      const asyncLoadAvailable = async () => {
        const response = await formsRestore();
        if (response) {
          const propertySubmission = response?.find(
            (item: { [key: string]: any }) =>
              item.title === "Property Submission",
          );
          const url = propertySubmission ? propertySubmission.url : "";
          setFormUrl(url);
        }
      };
      asyncLoadAvailable();
    }
  }, [isLicensee]);

  const formatFilterString = (filterString: string) => {
    return filterString
      .replace(/filters\[/g, "") // Remove 'filters['
      .replace(/\]/g, "") // Remove ']'
      .replace(/([^\]=]+)=/g, "$1=") // Replace '=' after keys
      .replace(/(\d)(\w+=)/g, "$1&$2"); // Insert '&' between key-value pairs
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (filterText) params.set("filterText", filterText);
    if (sort) params.set("sort", sort);
    if (filter) {
      const readableFilter = formatFilterString(filter);
      const filterParams = readableFilter.split("&");
      filterParams.forEach((param) => {
        const [key, value] = param.split("=");
        params.set(key, value);
      });
    }
    if (filterPriceRange) {
      const readableFilterPriceRange = formatFilterString(filterPriceRange);
      const filterPriceRangeParams = readableFilterPriceRange.split("&");
      filterPriceRangeParams.forEach((param) => {
        const [key, value] = param.split("=");
        params.set(key, value);
      });
    }

    let query = params.toString().replace(/%2C/g, ",");
    if (queryAvailability && !filterText) {
      query += "&" + queryAvailability;
    }

    history.push({ search: query });
  }, [filterText, sort, filter, filterAvailability, filterPriceRange]);

  useEffect(() => {
    if (minPrice && maxPrice) {
      if (!selectedMinPrice && !selectedMaxPrice) {
        setSelectedMinPrice(minPrice);
        setSelectedMaxPrice(maxPrice);
      }

      if (
        selectedMinPrice &&
        selectedMaxPrice &&
        (selectedMinPrice < minPrice || selectedMaxPrice > maxPrice)
      ) {
        const filters = [];
        filters.push(`filters[minPrice]=${minPrice}`);
        setSelectedMinPrice(minPrice);
        filters.push(`filters[maxPrice]=${maxPrice}`);
        setSelectedMaxPrice(maxPrice);
        setFilterPriceRange(filters.join("&"));
      }
    }
  }, [minPrice, maxPrice]);

  const resetSelectedPriceRange = () => {
    setSelectedMinPrice("");
    setSelectedMaxPrice("");
    setFilterPriceRange("");
  };

  useEffect(() => {
    if (
      !props.availableFrom ||
      !props.availableTo ||
      !props.minPrice ||
      !props.maxPrice
    ) {
      return;
    }

    setSelectedMinPrice(props.minPrice);
    setSelectedMaxPrice(props.maxPrice);
    setFilterPriceRange(
      `filters[minPrice]=${props.minPrice}&filters[maxPrice]=${props.maxPrice}`,
    );
  }, [props.minPrice, props.maxPrice, props.availableFrom, props.availableTo]);
  const isOwner = isAuthorized([ROLE.OWNER], roles);
  return (
    <PropertyListWrapper floating={floating} mounted={mounted}>
      <HeaderWrapper>
        <Header lazyLoad={lazyLoad}>Properties</Header>
        {isLicensee && (
          <Button
            label="Add New Property"
            icon="plus"
            mt={16}
            mb={0}
            onClick={() => window.open(formUrl, "_blank")}
            styling={BUTTON_INLINE}
            testId="add new property"
            type={BUTTON_PRIMARY}
          />
        )}
      </HeaderWrapper>
      <Wrapper isOwner={isOwner}>
        <SearchWrapper>
          {renderSearch()}
          {!lazyLoad && renderInActive()}
        </SearchWrapper>
        {lazyLoad && !isOwner && <Body color="#939296">or</Body>}
        {lazyLoad && !isOwner && (
          <SearchAvailability
            isAvailability={filterText}
            setCheckInDate={setCheckInDate}
            setCheckOutDate={setCheckOutDate}
            setClearSearch={setClearSearch}
            setFilterAvailability={setFilterAvailability}
            setFlag={setFlag}
            setQueryAvailability={setQueryAvailability}
            resetSelectedPriceRange={resetSelectedPriceRange}
          />
        )}
      </Wrapper>
      {lazyLoad && (
        <FlexWrapper isOwner={isOwner}>
          <Sort
            isLicensee={isAuthorized([ROLE.LICENSEE], roles)}
            isOwner={isAuthorized([ROLE.OWNER], roles)}
            setSort={setSort}
            sort={sort}
            isPriceRange={isDateRange && minPrice && maxPrice}
          />
          <Filter
            isLicensee={isAuthorized([ROLE.LICENSEE], roles)}
            isOwner={isOwner}
            setFilter={setFilter}
            isPriceRange={isDateRange && minPrice && maxPrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            selectedMinPrice={selectedMinPrice}
            selectedMaxPrice={selectedMaxPrice}
            setSelectedMinPrice={setSelectedMinPrice}
            setSelectedMaxPrice={setSelectedMaxPrice}
            setFilterPriceRange={setFilterPriceRange}
          />
        </FlexWrapper>
      )}
      {(error || connectionError) && renderError()}
      {items.length > 0 && (
        <>
          {lazyLoad && (
            <FlexContainer>
              <Count>
                {totalCount} {totalCount === 1 ? `result` : "results"}
              </Count>
              {isDateRange && (
                <CheckBox
                  my={0}
                  name="corporate"
                  options={[
                    {
                      value: "corporate",
                      label: "Show corporate rate",
                    },
                  ]}
                  sendValue={({ value }: any) => setShowCorporate(value)}
                  testId="corporate"
                />
              )}
            </FlexContainer>
          )}
          <PropertyTable
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            floating={floating}
            handleClick={props?.handleClick}
            height={floating ? 450 : 0}
            isContentCreator={isAuthorized([ROLE.CONTENT_CREATOR], roles)}
            isDateRange={isDateRange}
            isLicensee={isAuthorized([ROLE.LICENSEE], roles)}
            isReservationManager={isAuthorized(
              [ROLE.RESERVATION_MANAGER],
              roles,
            )}
            isOwner={isAuthorized([ROLE.OWNER], roles)}
            isStaff={isAuthorized([ROLE.STAFF], roles)}
            isEditor={isAuthorized([ROLE.PROPERTY_EDITOR], roles)}
            properties={lazyLoad ? items : propertyList}
            showButtons={showButtons}
            showPropertyListPage={lazyLoad}
            showCorporate={showCorporate}
          />
          {lazyLoad && (
            <div
              ref={loader}
              style={{ height: "10px", background: "transparent" }}
            ></div>
          )}
        </>
      )}
      {pending && <Loading task="restoringPropertyList" />}
      {items && items.length === 0 && !pending && success && (
        <Body center>No results found</Body>
      )}
    </PropertyListWrapper>
  );
};

const PropertyListWithClickOutside = withClickOutside(PropertyList);

export default withRouter(
  withURLQuery(() => [
    { key: "filterText", type: "string" },
    { key: "sort", type: "string" },
    { key: "minPrice", type: "string" },
    { key: "maxPrice", type: "string" },
    { key: "availableFrom", type: "string" },
    { key: "availableTo", type: "string" },
  ])(PropertyList),
);
export { PropertyListWithClickOutside };
