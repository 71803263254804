// common elements used by DatePicker and TimePicker
import React from "react";
import styled, { css } from "styled-components";

import { ErrorMessage } from "@madecomfy/webooi";

import crossIcon from "Components/Popup/assets/crossIcon.svg";

interface ButtonClearProps {
  handleClick: (...args: any) => any;
  showClear: boolean;
}

interface WarningProps {
  error: string | null;
}

export const Wrapper = styled.div<any>`
  ${({ isEnabled }) =>
    !isEnabled &&
    css`
      pointer-events: none;
    `}
`;

export const WrapperPicker = styled.div<any>`
  position: relative;
  ${({ isEnabled }) =>
    !isEnabled &&
    css`
      pointer-events: none;
    `}
  .instruction-wrapper {
    margin-top: -4px;
  }
`;

export const WrapperInputs = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 784px;

  ${({ singleDate }) =>
    singleDate
      ? css`
          > div {
            width: 100%;
          }
        `
      : css`
          > div {
            width: 50%;
            :nth-child(1) {
              margin-right: 8px;
            }
            :nth-child(2) {
              margin-left: 8px;
            }
          }
        `}
  white-space: nowrap;
`;

export const WrapperInput = styled.div<any>`
  align-items: center;
  background: ${({ isEnabled, theme }) =>
    isEnabled ? theme.white : theme.charcoalLight10};
  border-radius: 4px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  line-height: 24px;
  padding: 0 8px;
  transition: border 0.15s linear, box-shadow 0.3s linear;
  ${({ inActive }) =>
    inActive &&
    css`
      pointer-events: none;
      background: ${({ theme }) => theme.charcoalLight10};
    `}
  svg {
    margin: 0;
    margin-top: 5px;
  }

  ${({ current }) =>
    current
      ? css`
          box-shadow: ${({ theme }) => theme.button.focus};
          border: 1px solid ${({ theme }) => theme.charcoal};
        `
      : css`
          border: 1px solid ${({ theme }) => theme.charcoalLight50};
          cursor: pointer;
        `};
`;

const CloseIcon = styled.img<any>`
  cursor: pointer;
  transition: transform 0.15s ease-in-out;
  ${({ showClear }) =>
    `transform: ${showClear ? `scale(1)` : `scale(0) rotate(90deg)`};`}
  :hover {
    ${({ showClear }) => `transform: scale(${showClear ? 1.2 : 0});`}
  }
`;

export const ButtonClear = ({ handleClick, showClear }: ButtonClearProps) => {
  return (
    <CloseIcon
      alt="clear"
      onClick={handleClick}
      showClear={showClear}
      src={crossIcon}
      data-test="clear-icon"
    />
  );
};

const WrapperWarning = styled.div<any>`
  opacity: ${({ error }) => (error ? 1 : 0)};
  margin-top: 8px;
`;

export const Warning = ({ error }: WarningProps) => (
  <WrapperWarning error={error}>
    <ErrorMessage
      error={
        error || "" // text is here just to always render something to DOM, to prevent elements shifting around
      }
      testId="datepicker"
    />
  </WrapperWarning>
);
