import get from "lodash/get";
import React from "react";
import styled from "styled-components";
import { OneOfType, tupleString } from "Utils/tuple";

import Bank from "./Bank";
import Book from "./Book";
import Calendar from "./Calendar";
import Channel from "./Channel";
import Experiment from "./Experiment";
import FAQ from "./FAQ";
import House from "./House";
import Key from "./Key";
import Knowledge from "./Knowledge";
import OpenTask from "./OpenTask";
import Resources from "./Resources";
import Rocket from "./Rocket";
import Statement from "./Statement";
import Suitcase from "./Suitcase";
import Users from "./Users";

export const ICON_BANK = "bank";
export const ICON_BOOK = "book";
export const ICON_CALENDAR = "calendar";
export const ICON_CHANNEL = "channel";
export const ICON_EXPERIMENT = "experiment";
export const ICON_FAQ = "faq";
export const ICON_HOUSE = "house";
export const ICON_KEY = "key";
export const ICON_KNOWLEDGE = "knowledge";
export const ICON_OPENTASK = "opentask";
export const ICON_RESOURCES = "resources";
export const ICON_ROCKET = "rocket";
export const ICON_STATEMENT = "statement";
export const ICON_SUITCASE = "suitcase";
export const ICON_USERS = "users";

const paths = {
  [ICON_BANK]: Bank,
  [ICON_BOOK]: Book,
  [ICON_CALENDAR]: Calendar,
  [ICON_CHANNEL]: Channel,
  [ICON_EXPERIMENT]: Experiment,
  [ICON_FAQ]: FAQ,
  [ICON_HOUSE]: House,
  [ICON_KEY]: Key,
  [ICON_KNOWLEDGE]: Knowledge,
  [ICON_OPENTASK]: OpenTask,
  [ICON_RESOURCES]: Resources,
  [ICON_ROCKET]: Rocket,
  [ICON_STATEMENT]: Statement,
  [ICON_SUITCASE]: Suitcase,
  [ICON_USERS]: Users,
};

const orientations = { up: 0, right: 90, down: 180, left: 270 };

const iconList = tupleString(
  ICON_BANK,
  ICON_BOOK,
  ICON_CALENDAR,
  ICON_CHANNEL,
  ICON_EXPERIMENT,
  ICON_FAQ,
  ICON_HOUSE,
  ICON_KEY,
  ICON_KNOWLEDGE,
  ICON_OPENTASK,
  ICON_RESOURCES,
  ICON_ROCKET,
  ICON_STATEMENT,
  ICON_SUITCASE,
  ICON_USERS,
);

export type IconName = OneOfType<typeof iconList>;

interface IProps {
  className?: string;
  fill: string; // TODO rename to colour
  orientation?: "up" | "right" | "down" | "left"; // up is default
  size: 12 | 16 | 24 | 32 | 48;
  type: IconName;
  viewBox?: string; // for images have origin viewBox different 16
}

// Don't put rollover effects here, do it in the parent.
export const SVG = styled.svg<any>`
  ${({ size }: { size: number }) =>
    size && `height: ${size}px; width: ${size}px;`}
  ${({ fill, theme }: any) =>
    fill &&
    `fill: ${get(
      theme,
      fill, // attempt to get fill from theme, eg 'theme.numberIncrementor.text' ...
      fill, // but if it doesn't exist, `fill` may be a hardcoded hexcode, so fallback to that.
    )};`}
  transition: transform 0.23s ease-in-out;
  ${({ rotation }: { rotation: number }) => `transform: rotate(${rotation}deg)`}
`;

const Icon: React.FC<IProps> = (props: IProps) => {
  const {
    className,
    orientation = "up",
    type,
    fill,
    size,
    viewBox = "0 0 24 24",
  } = props;
  const Path = paths[type];
  const rotation = orientations[orientation];
  return (
    <SVG
      className={className} // This is from dynamic classes, eg NumberIncrementor ButtonIcon
      fill={fill}
      rotation={rotation}
      size={size}
      viewBox={viewBox}
      style={{ width: size, height: size }} // bug with SSR in fitzgerald, see note above
    >
      <Path />
    </SVG>
  );
};

export default Icon;
