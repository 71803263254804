import React from "react";

const Calendar = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9.4707C3 9.8849 3.33578 10.2207 3.75 10.2207C4.16422 10.2207 4.5 9.8849 4.5 9.4707C4.5 9.0565 4.16422 8.7207 3.75 8.7207C3.33578 8.7207 3 9.0565 3 9.4707Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12.9707C3 13.3849 3.33578 13.7207 3.75 13.7207C4.16422 13.7207 4.5 13.3849 4.5 12.9707C4.5 12.5565 4.16422 12.2207 3.75 12.2207C3.33578 12.2207 3 12.5565 3 12.9707Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8.7207C8.41421 8.7207 8.75 9.05649 8.75 9.4707C8.75 9.88491 8.41421 10.2207 8 10.2207C7.58579 10.2207 7.25 9.88491 7.25 9.4707C7.25 9.05649 7.58579 8.7207 8 8.7207Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12.2207C8.41421 12.2207 8.75 12.5565 8.75 12.9707C8.75 13.3849 8.41421 13.7207 8 13.7207C7.58579 13.7207 7.25 13.3849 7.25 12.9707C7.25 12.5565 7.58579 12.2207 8 12.2207Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 8.7207C12.6642 8.7207 13 9.05649 13 9.4707C13 9.88491 12.6642 10.2207 12.25 10.2207C11.8358 10.2207 11.5 9.88491 11.5 9.4707C11.5 9.05649 11.8358 8.7207 12.25 8.7207Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 12.2207C12.6642 12.2207 13 12.5565 13 12.9707C13 13.3849 12.6642 13.7207 12.25 13.7207C11.8358 13.7207 11.5 13.3849 11.5 12.9707C11.5 12.5565 11.8358 12.2207 12.25 12.2207Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 0.220703C4.77614 0.220703 5 0.444561 5 0.720703V2.2207H11V0.720703C11 0.444561 11.2239 0.220703 11.5 0.220703C11.7761 0.220703 12 0.444561 12 0.720703V2.2207H14.5C15.3284 2.2207 16 2.89227 16 3.7207V14.7207C16 15.5491 15.3284 16.2207 14.5 16.2207H1.5C0.671564 16.2207 0 15.5491 0 14.7207V3.7207C0 2.89227 0.671571 2.2207 1.5 2.2207H4V0.720703C4 0.444561 4.22386 0.220703 4.5 0.220703ZM11 4.2207C11 4.49685 11.2239 4.7207 11.5 4.7207C11.7761 4.7207 12 4.49685 12 4.2207V3.2207H14.5C14.7761 3.2207 15 3.44457 15 3.7207V6.2207H1V3.7207C1 3.44456 1.22386 3.2207 1.5 3.2207H4V4.2207C4 4.49685 4.22386 4.7207 4.5 4.7207C4.77614 4.7207 5 4.49685 5 4.2207V3.2207H11V4.2207ZM1 7.2207H15V14.7207C15 14.9968 14.7761 15.2207 14.5 15.2207H1.5C1.22386 15.2207 1 14.9968 1 14.7207V7.2207Z"
    />
  </>
);

export default Calendar;
