import React, { Component } from "react";
import styled, { css } from "styled-components";

import { InputTitleLabel, Instructions } from "@madecomfy/webooi";
import Icon from "Components/Icon";
// to move this into webooi these imports will have to be sorted:
import withClickOutside from "HOC/withClickOutside";
import { formatDateWithDynamic } from "Utils";

import Month from "./Month";
import { dayYYYYMMDD, dayDDMMYYYY } from "./utils";

import {
  ButtonClear,
  Warning,
  WrapperInput,
  WrapperInputs,
  WrapperPicker,
} from "./PickerElements";

export const START_DATE = "startDate";
export const END_DATE = "endDate";

// no point supporting before airbnb's existence, and adding our own special y2k1c bug variant for 2100
export const YEAR_EARLIEST = "2010";
export const YEAR_LATEST = "2100";

interface IProps {
  calendar: { [key: string]: any };
  id?: string;
  ignoreValidation?: boolean;
  inActive?: boolean;
  initialFocus?: boolean;
  initialDates?: {
    startDate?: string;
    endDate?: string;
  };
  isEnabled?: boolean;
  label?: string | any[];
  month: number;
  name?: string;
  numMonths?: number;
  placeholders?: {
    startDate?: string;
    endDate?: string;
  };
  restrictedDates?: {
    startDate?: string;
    endDate?: string;
  };
  reset?: boolean;
  sendValue: (...args: any) => any;
  singleDate?: boolean;
  testId?: string;
  year?: number;
  instructions?: any;
  formatDayOfMonth?: string;
  userRoles?: string[];
  isClearable?: boolean;
}

const WrapperCalendar = styled.div<any>`
  position: absolute;
  background: white;
  border: 1px solid ${({ theme }) => theme.charcoalLight10};
  border-radius: 4px;
  margin-top: 8px;
  padding: 16px;
  width: 100vw;
  max-width: 368px;
  z-index: 5;
  ${({ focus }) =>
    focus === START_DATE
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
`;

const DateText = styled.div<any>`
  width: ${({ isEnabled, isClearable }) =>
    isEnabled && isClearable ? `calc(100% - 40px)` : `calc(100% - 16px);`};
  font-size: ${({ isClearable }) => (isClearable ? "16px" : "1rem")};
`;

const WrapperLabel = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 784px;
  > label {
    width: 50%;
  }
  > label:nth-child(1) {
    margin-right: 8px;
  }
  > label:nth-child(2) {
    margin-left: 8px;
  }
`;

const DateInput = ({
  focus,
  id,
  inActive,
  isEnabled,
  name,
  onClear,
  onFocus,
  placeholder,
  value,
  formatDayOfMonth,
  isClearable = true,
}: any) => {
  const showClear = Boolean(value);
  const formatDay = formatDayOfMonth
    ? (date: any) => formatDateWithDynamic(date, formatDayOfMonth, null)
    : dayDDMMYYYY;
  return (
    <WrapperInput
      className="picker-element"
      isEnabled={isEnabled}
      id={id}
      inActive={inActive}
      current={focus === id}
      onClick={() => onFocus(id)}
    >
      <Icon type="calendar" size={24} fill="#303336" />
      <DateText isEnabled={isEnabled} isClearable={isClearable} name={name}>
        {value ? formatDay(value) : placeholder}
      </DateText>
      {isEnabled && isClearable && (
        <ButtonClear handleClick={onClear} showClear={showClear} />
      )}
    </WrapperInput>
  );
};

const inactive = {
  range: {},
  startDate: "",
  endDate: "",
};

const calcNext = (y: any, m: any, delta: any) => {
  let nextYear = y;
  let nextMonth = m + delta;
  // this logic is dodgy... works for deltas of +/- 1 though, fix if/when numMonths is > 1
  if (nextMonth < 0) {
    nextMonth = 11;
    nextYear--;
  }
  if (nextMonth > 11) {
    nextMonth = 0;
    nextYear++;
  }

  if (nextYear < YEAR_EARLIEST || nextYear > YEAR_LATEST) {
    return { month: m, year: y };
  }

  return { month: nextMonth, year: nextYear };
};

export const getVacancyFromCalendar = (
  calendar: { [key: string]: any },
  dmy: string | number,
) => {
  if (calendar.vacanciesMap.empty) {
    return {
      startDate: `${YEAR_EARLIEST}-01-01`,
      endDate: `${YEAR_LATEST}-12-31`,
    };
  }
  const vacancyIndex = calendar.vacanciesMap[dmy];

  if (typeof vacancyIndex === "number") {
    return calendar.eventsArray[vacancyIndex];
  }

  if (dmy <= calendar.vacanciesMap.firstEvent) {
    return {
      startDate: `${YEAR_EARLIEST}-01-01`,
      endDate: calendar.vacanciesMap.firstEvent,
    };
  }
  if (dmy >= calendar.vacanciesMap.lastEvent) {
    return {
      startDate: calendar.vacanciesMap.lastEvent,
      endDate: `${YEAR_LATEST}-12-31`,
    };
  }

  return null;
};

class DatePicker extends Component<IProps> {
  state = {
    active: inactive,
    error: null,
    focus: null,
    mouseOver: null,
    viewing: {
      month: this.props.month - 1,
      year: this.props.year,
    },
  };

  componentDidMount() {
    this.getStateFromProps();
  }

  getStateFromProps = () => {
    const {
      calendar,
      ignoreValidation,
      initialDates,
      initialFocus,
      isEnabled,
      month,
      year,
    } = this.props;
    const viewing = { month: month - 1, year };
    const initialStartDate = initialDates && initialDates.startDate;
    const initialEndDate = initialDates && initialDates.endDate;
    if (!initialStartDate) {
      this.setState({
        viewing,
        focus: initialFocus === false ? null : START_DATE,
      });
      return;
    }
    if (!calendar || !calendar.vacanciesMap) {
      // eslint-disable-next-line no-console
      console.warn("no vacanciesMap!");
      return;
    }

    const range = getVacancyFromCalendar(calendar, initialStartDate);

    if (ignoreValidation === false && isEnabled && !range) {
      // eslint-disable-next-line no-console
      console.warn("no range found and isEnabled, this is an error!");
      return;
    }

    let focus = null;
    if (initialFocus !== false) {
      focus = initialStartDate && initialEndDate ? START_DATE : END_DATE;
    }

    const { active } = this.state;
    this.setState({
      active: {
        ...active,
        range,
        startDate: initialStartDate,
        endDate: initialEndDate,
      },
      focus,
      viewing,
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const { sendValue, reset = false } = this.props;
    const { active } = this.state;

    // Check if reset prop has changed
    if (prevProps.reset !== reset && reset) {
      // If reset is true, clear the dates
      this.setState({
        active: {
          startDate: "",
          endDate: "",
        },
      });
      sendValue({ startDate: "", endDate: "" });
    } else if (
      prevState.active.startDate !== active.startDate ||
      prevState.active.endDate !== active.endDate
    ) {
      // If dates have changed and reset is not true, send the updated dates
      sendValue(active);
    }
  }

  render() {
    const {
      calendar,
      id,
      ignoreValidation = false,
      inActive = false,
      isEnabled = true,
      label,
      name,
      numMonths = 1,
      placeholders,
      restrictedDates,
      singleDate = false,
      instructions,
      formatDayOfMonth,
      isClearable,
    }: // testId, <- not currently implemented
    any = this.props;
    const setActive = (active: { [key: string]: any }) =>
      this.setState({ active });
    const setError = (error: any) => this.setState({ error });
    const setFocus = (focus: string | null) => this.setState({ focus });
    const setMouseOver = (mouseOver: any) => this.setState({ mouseOver });

    const { active, error, focus, mouseOver, viewing } = this.state;

    const { startDate, endDate } = active;

    const onIncrement = (delta: any) => (e: any) => {
      this.setState({ viewing: calcNext(viewing.year, viewing.month, delta) });
    };

    const onDayClick = ({ error, dmy, range }: any) => {
      if (error) {
        setError(error);
        return;
      }
      setError(null);

      // valid, set it.
      setMouseOver(null);

      if (singleDate) {
        setActive({ ...active, range, startDate: dmy, endDate: dmy });
        setFocus(null);
        return;
      }

      setActive({ ...active, range, [focus as any]: dmy });

      // and choose what action to do next....
      if (focus === START_DATE) {
        if (active.endDate) {
          // clear the end date if start date is changed
          setActive({ ...active, startDate: dmy, endDate: "" });
          setFocus(END_DATE);
        } else {
          setFocus(END_DATE);
        }
      } else if (focus === END_DATE) {
        if (active.startDate) {
          setFocus(null);
        } else {
          setFocus(START_DATE);
        }
      }
    };

    let t: any;
    const onDayHover = (dmy: any) => {
      // prevent double call: onDayHover(null) -> onDayHover("YYYY-MM-DD")
      // which happens when you rollout of a Day and on to another Day.
      if (t) {
        clearTimeout(t);
      }
      t = setTimeout(() => {
        setMouseOver(focus && dmy);
        clearTimeout(t);
      }, 1);
    };

    const monthProps = {
      calendar,
      ignoreValidation,
      onDayClick,
      onDayHover,
      viewingMonth: false,
      showDaysOutsideMonth: true,
      active,
      focus,
      mouseOver,
      singleDate,
      restrictedDates,
      ...viewing,
    };

    return (
      <WrapperPicker isEnabled={isEnabled}>
        {label &&
          (Array.isArray(label) ? (
            <WrapperLabel>
              {label.map((l, i) => {
                return <InputTitleLabel key={i}>{l}</InputTitleLabel>;
              })}
            </WrapperLabel>
          ) : (
            <InputTitleLabel>{label}</InputTitleLabel>
          ))}
        {instructions && (
          <div className="instruction-wrapper">
            <Instructions>{instructions}</Instructions>
          </div>
        )}
        <WrapperInputs singleDate={singleDate}>
          <DateInput
            focus={focus}
            isEnabled={isEnabled}
            id={id || START_DATE}
            inActive={inActive}
            onClear={() => {
              if (singleDate) {
                setActive(inactive);
                return;
                // the end date is hidden, so no need to check further...
              }

              if (active.endDate) {
                setActive({ ...active, startDate: "" });
              } else {
                setActive(inactive);
              }
            }}
            onFocus={setFocus}
            name={singleDate ? name : `${name}-start-date`}
            placeholder={placeholders?.startDate || "Start"}
            value={startDate}
            formatDayOfMonth={formatDayOfMonth}
            isClearable={isClearable}
          />
          {singleDate === false && (
            <DateInput
              focus={focus}
              isEnabled={isEnabled}
              id={END_DATE}
              onClear={() => {
                if (active.startDate) {
                  setActive({ ...active, endDate: "" });
                } else {
                  setActive(inactive);
                }
              }}
              onFocus={setFocus}
              name={`${name}-end-date`}
              placeholder={placeholders?.endDate || "End"}
              value={endDate}
              formatDayOfMonth={formatDayOfMonth}
              isClearable={isClearable}
            />
          )}
        </WrapperInputs>

        {ignoreValidation === false && <Warning error={error} />}

        <Calendar
          isDisplayed={Boolean(isEnabled && focus)}
          // @ts-ignore
          focus={focus}
          monthProps={monthProps}
          numMonths={numMonths}
          onIncrement={onIncrement}
          onClickOutside={(event: Event) => {
            const target = event?.target as HTMLElement;
            const id = target?.id;
            const parentId = (target?.parentNode as HTMLElement)?.id;
            if (
              id === START_DATE ||
              id === END_DATE ||
              parentId === START_DATE ||
              parentId === END_DATE
            ) {
              return;
            }
            setFocus(null);
          }}
          restrictedDates={restrictedDates}
          viewing={viewing}
        />
      </WrapperPicker>
    );
  }
}

const Calendar = withClickOutside(
  ({
    isDisplayed,
    focus,
    monthProps,
    numMonths,
    onIncrement,
    viewing,
  }: any) => {
    if (!isDisplayed) return null;
    const today = dayYYYYMMDD(new Date());

    const onNavigate = (direction: number) =>
      onIncrement(direction * numMonths);

    return (
      <WrapperCalendar className="calendar-month-wrapper" focus={focus}>
        <Month {...monthProps} today={today} onNavigate={onNavigate} />
      </WrapperCalendar>
    );

    // numMonths > 1 currently disabled.
    /*
    return (
      <WrapperCalendar>
        {numMonths === 1 ? (
          <Month {...monthProps} today={today} onNavigate={onNavigate} />
        ) : (
          range(0, numMonths).map((index) => {
            // const monthIndex = month + index;
            const offset = calcNext(viewing.year, viewing.month, index);
            return (
              <Month
                {...monthProps}
                {...offset}
                onNavigate={onNavigate}
                key={`month-${index}`}
                today={today}
              />
            );
          })
        )}
      </WrapperCalendar>
    );
    */
  },
);

export const DatePickerStatic = ({
  singleDate = false,
  startDate,
  endDate,
  label,
}: any) => {
  return (
    <WrapperPicker isEnabled={false}>
      <InputTitleLabel>{label}</InputTitleLabel>
      <WrapperInputs>
        <DateInput
          isEnabled={false}
          focus={false}
          id={START_DATE}
          placeholder="Start"
          value={startDate}
        />
        {singleDate === false && (
          <DateInput
            isEnabled={false}
            focus={false}
            id={END_DATE}
            placeholder="End"
            value={endDate}
          />
        )}
      </WrapperInputs>
    </WrapperPicker>
  );
};

DatePickerStatic.defaultProps = {
  singleDate: false,
};

export default DatePicker;
