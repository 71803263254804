import React from "react";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";

import { createPassword } from "Actions/authentication/createPassword";
import { verifyToken } from "Actions/authentication/verifyToken";
import { forgotPassword } from "Actions/authentication/forgotPassword";
import { login, resetLogin } from "Actions/authentication/login";

import { WelcomeCreatePassword, WelcomeForgotPassword, WelcomeLogin } from ".";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 466px;
  min-height: 500px;
  position: relative;
  text-align: center;
  width: 100%;
  padding: 0 24px;
`;

const WelcomeBackground = styled.div`
  background-attachment: fixed;
  background-color: #346b82;
  background-image: url("/img/gradient-bg.jpg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 1500px 450px;
  height: 450px;
  position: absolute;
  width: 100%;
`;

interface IProps {
  authentication: { [key: string]: any };
  createPassword: (...args: any) => any;
  forgotPassword: (...args: any) => any;
  history: any;
  login: (...args: any) => any;
  match: { [key: string]: any };
  resetLogin: (...args: any) => any;
  verifyToken: (...args: any) => any;
}
const Welcome: React.FC<IProps> = (props) => {
  const {
    authentication,
    createPassword,
    forgotPassword,
    history,
    login,
    match,
    resetLogin,
    verifyToken,
  } = props;

  const {
    createPasswordStatus,
    verifyTokenStatus,
    forgotPassword: forgotPasswordStatus,
    sessionStatus,
  } = authentication;

  return (
    <Router {...props}>
      <WelcomeBackground />
      <Wrapper id="WelcomeWrapper">
        <Switch>
          <Route
            exact
            path="/login/:action/:newPasswordToken"
            render={() => (
              <WelcomeCreatePassword
                createPassword={createPassword}
                createPasswordStatus={createPasswordStatus}
                verifyToken={verifyToken}
                verifyTokenStatus={verifyTokenStatus}
                match={match}
              />
            )}
          />
          <Route
            exact
            path="/forgot"
            render={() => (
              <WelcomeForgotPassword
                forgotPassword={forgotPassword}
                forgotPasswordStatus={forgotPasswordStatus}
              />
            )}
          />
          <Route
            exact
            path="/login"
            render={() => (
              <WelcomeLogin
                history={history}
                login={login}
                sessionStatus={sessionStatus}
                resetLogin={resetLogin}
              />
            )}
          />
        </Switch>
      </Wrapper>
    </Router>
  );
};

function mapDispatchToProps(dispatch: any) {
  return {
    createPassword: bindActionCreators(createPassword, dispatch),
    verifyToken: bindActionCreators(verifyToken, dispatch),
    forgotPassword: bindActionCreators(forgotPassword, dispatch),
    login: bindActionCreators(login, dispatch),
    resetLogin: bindActionCreators(resetLogin, dispatch),
  };
}

function mapStateToProps(state: any) {
  return {
    authentication: state.authentication,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
