import "isomorphic-fetch";
import get from "lodash/get";

import Config from "Config";
import { propertiesCountRestore } from "Actions/properties/propertiesCountRestore";
import { SESSION } from "Constants/actionTypes";
import { setUser } from "ThirdParty";

export function validateToken() {
  // token is extracted from cookie by backend
  return async (dispatch: (...args: any) => any) => {
    try {
      dispatch({ type: SESSION.PENDING, status: true });
      const response = await fetch(Config.API_URL + "v3/ping?include=user", {
        credentials: "include",
        mode: "cors",
        method: "GET",
      });
      const json = await response.json();
      const user = get(json, "included[0].attributes");
      const impersonated = json?.data?.attributes?.impersonated;
      if (impersonated) {
      user.impersonated = impersonated;
      }
      if (!user) {
        dispatch({ type: SESSION.RESET });
        return;
      }
      setUser(user);
      user.propertyStatus = await propertiesCountRestore();
      dispatch({ type: SESSION.SUCCESS, user });
    } catch (err) {
      dispatch({ type: SESSION.ERROR }); // no message or anything, just redirect user to login
    }
  };
}
