import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";

import {
  Button,
  BUTTON_INLINE,
  BUTTON_PRIMARY,
  // InputText,
  NumberIncrementorDropDown,
  // TYPE_TEXT,
} from "@madecomfy/webooi";

import { blankCalendar, DatePicker } from "Components/Dates";
import { getPointByDistanceAndBearing } from "./getPoint";
import restoreLocations from "Actions/properties/propertyLocations";

export const WrapperFlex = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-right: 24px;
  margin-bottom: 24px;
  @media only screen and (max-width: 750px) {
    width: 100%;
    margin-left: -24px;
  }
`;
const Flex = styled(WrapperFlex)`
  margin: 0;
  gap: 8px;
  div#startDate {
    margin: 0;
  }
  div[data-test="input-number-incrementor-guests"],
  input[data-test="input-text-location-search"] {
    padding-right: 8px;
    padding-left: 8px;
  }
  svg[fill="numberIncrementor.text"] {
    display: none;
  }
  div[data-test="drop-down"] {
    z-index: 9;
    svg[fill="numberIncrementor.text"] {
      display: block;
    }
  }
  div.calendar-month-wrapper {
    z-index: 99;
  }
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    width: 100%;
  }
`;
const WrapperButton = styled.div<{ isEnabled: boolean }>`
  cursor: ${({ isEnabled }) => (isEnabled ? "pointer" : "none")};
`;

const Input = styled.input`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  box-sizing: border-box;
  color: #4a4f54;
  font-size: 16px;
  outline: none;
  transition: border 0.15s linear, box-shadow 0.3s linear;
  --webkit-appearance: none;
  height: 48px;
  padding: 16px 8px;
  :focus {
    border: 1px solid ${({ theme }) => theme.navy};
    box-shadow: 0px 0px 6px rgba(52, 101, 127, 0.7);
  }
`;
const LocationContainer = styled.div<any>`
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 1;
  top: 53px;
`;

const LocationItem = styled.p<any>`
  background-color: white;
  border-bottom: 2px solid ${({ theme }) => theme.sand94};
  color: black;
  cursor: pointer;
  padding: 16px;
  margin: 0;
  ${({ isHighlighted, theme }) =>
    isHighlighted && `background-color: ${theme.sand94}`};
`;

const InputWrapper = styled.div`
  position: relative;
`;

interface IProps {
  isAvailability: string;
  setCheckInDate: Function;
  setCheckOutDate: Function;
  setClearSearch: Function;
  setFilterAvailability: Function;
  setFlag: Function;
  setQueryAvailability: Function;
  resetSelectedPriceRange: Function;
}

export const SearchAvailability: React.FC<IProps> = ({
  isAvailability,
  setCheckInDate,
  setCheckOutDate,
  setClearSearch,
  setFilterAvailability,
  setFlag,
  setQueryAvailability,
  resetSelectedPriceRange,
}) => {
  const [availableFrom, setAvailableFrom] = useState<string>(
    isAvailability && "",
  );

  const [availableTo, setAvailableTo] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [guests, setGuests] = useState(2);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [bounds, setBounds] = useState({} as any);
  const [placeName, setPlaceName] = useState("");
  const [restorePlaceName, setRestorePlaceName] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const params = new URLSearchParams(location.search);
  const urlAdults = params.get("adults");
  const urlChildren = params.get("children");
  const urlAvailableFrom = params.get("availableFrom");
  const urlAvailableTo = params.get("availableTo");

  const [guestOptions, setGuestOptions] = useState([
    {
      label: "Adults",
      instruction: "Ages 13 or above",
      maxVal: 10,
      minVal: 1,
      val: 2,
    },
    {
      label: "Children",
      instruction: "Ages 2-12",
      maxVal: 10,
      minVal: 0,
      val: 0,
    },
  ]);

  const locationCallback = useCallback(
    debounce((text: string) => {
      searchLocation(text);
    }, 300),
    [],
  );
  const isEnabled = Boolean(bounds?.nw?.lat || (availableFrom && availableTo));

  const displayGuestsSearch = (value: any) => {
    const n = Number(value[0] + value[1]);
    if (n === 1) return "1 Guest";
    if (n >= 10) return "10+ Guests";
    return `${n} Guests`;
  };

  const searchLocation = async (search: any) => {
    if (search.length < 2) {
      setIsFocus(false);
      setDisplaySuggestions(false);
      return;
    }
    const suggestions = await restoreLocations(search);
    setDisplaySuggestions(true);
    setSuggestions(suggestions);
  };

  const selectLocation = (suggestionIndex: number) => {
    const value = suggestions[suggestionIndex] as any;
    if (!value) {
      setIsFocus(false);
      setDisplaySuggestions(false);
      return;
    }
    // calculate the bounds manually
    const north = getPointByDistanceAndBearing(
      {
        lat: Number(value?.latitude),
        lng: Number(value?.longitude),
      },
      315, // NW degree
      5,
    );
    const south = getPointByDistanceAndBearing(
      {
        lat: Number(value.latitude),
        lng: Number(value.longitude),
      },
      135, // SE degree
      5,
    );
    const bounds = {
      nw: { lat: north.lat, lng: north.lng },
      se: { lat: south.lat, lng: south.lng },
    };
    setIsFocus(true);
    setDisplaySuggestions(false);
    setBounds(bounds);
    setPlaceName(value.name);
    setRestorePlaceName(value.name);
  };

  const handleBlur = (event: { target: { value: any } }) => {
    const search = event.target.value;
    if (search.length < 2) {
      setIsFocus(false);
      setPlaceName("");
      return;
    }
    if (isFocus) {
      setDisplaySuggestions(false);
      setPlaceName(restorePlaceName);
    } else {
      selectLocation(0);
    }
  };

  const handleChange = (event: { target: { value: any } }) => {
    const search = event.target.value;
    setPlaceName(search);
    locationCallback(search);
  };

  const handleKeyDown = (event: { which: any }) => {
    const keyCode = event.which;
    switch (keyCode) {
      case 40: // down arrow
        highlightLocation(currentIndex + 1);
        break;
      case 38: // up arrow
        highlightLocation(currentIndex - 1);
        break;
      case 13: // enter
        selectLocation(currentIndex);
        break;
    }
  };

  const highlightLocation = (currentIndex: any) => {
    if (currentIndex >= suggestions.length) return;
    if (currentIndex < 0) return;
    setCurrentIndex(currentIndex);
  };

  useEffect(() => {
    if (isAvailability) {
      setPlaceName("");
    }
  }, [isAvailability]);

  const updateFilters = (filters: string[]) => {
    if (availableFrom) {
      filters.push(`filters[availableFrom]=${availableFrom}`);
      setCheckInDate(availableFrom);
    } else {
      setAvailableFrom("");
      setAvailableTo("");
    }

    if (availableTo) {
      filters.push(`filters[availableTo]=${availableTo}`);
      setCheckOutDate(availableTo);
    } else {
      setAvailableTo("");
    }

    if (bounds?.nw?.lat && !!placeName) {
      filters.push(
        `filters[nwlat]=${bounds?.nw?.lat}&filters[nwlng]=${bounds?.nw?.lng}&filters[selat]=${bounds?.se?.lat}&filters[selng]=${bounds?.se?.lng}`,
      );
    }

    setFlag(false);
    setFilterAvailability(filters.join("&"));
  };

  const makeQueryParams = () => {
    let query = `adults=${adults}&children=${children}`;
    if (availableFrom && availableTo) {
      query += `&availableFrom=${availableFrom}&availableTo=${availableTo}`;
    }
    setQueryAvailability(query);
  };

  useEffect(() => {
    if (!availableFrom && !availableTo) {
      const filters: string[] = [];
      updateFilters(filters);
      setQueryAvailability("");
      resetSelectedPriceRange();
    }
  }, [availableFrom, availableTo]);

  const handleOnClick = () => {
    if (!bounds?.nw?.lat && !(availableFrom && availableTo)) {
      return;
    }

    setClearSearch(true);
    const filters = [
      `filters[isAvailabilitySearch]=true&filters[adults]=${guests}`,
    ];
    updateFilters(filters);
    makeQueryParams();
  };

  // Parse URL params and set state accordingly
  useEffect(() => {
    if (urlAdults) setAdults(Number(urlAdults));
    if (urlChildren) setChildren(Number(urlChildren));
    setGuests(adults + children);
    setGuestOptions((prevOptions) =>
      prevOptions.map((option) => {
        if (option.label === "Adults") {
          return { ...option, val: adults };
        }
        if (option.label === "Children") {
          return { ...option, val: children };
        }
        return option;
      }),
    );
    if (urlAvailableFrom) setAvailableFrom(urlAvailableFrom);
    if (urlAvailableTo) setAvailableTo(urlAvailableTo);

    handleOnClick();
  }, [urlAvailableFrom, urlAvailableTo, urlAdults, urlChildren]);
  return (
    <Flex>
      <InputWrapper>
        <Input
          autoComplete="off"
          name="location"
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Search location"
          type="text"
          value={placeName}
        />
        {displaySuggestions && (
          <LocationContainer data-test="location-input">
            {suggestions.length ? (
              suggestions.map((suggestion: any, i) => {
                return (
                  <LocationItem
                    key={`location-item-${i}`}
                    id={suggestion.id}
                    onMouseDown={() => selectLocation(currentIndex)} // onMouseDown is to trigger click in prior to blur
                    onMouseOver={() => setCurrentIndex(i)}
                    onMouseOut={() => setCurrentIndex(-1)}
                    isHighlighted={currentIndex === i}
                  >
                    {suggestion.name}
                  </LocationItem>
                );
              })
            ) : (
              <LocationItem>
                No locations found. Try searching by a suburb.
              </LocationItem>
            )}
          </LocationContainer>
        )}
      </InputWrapper>
      <DatePicker
        calendar={blankCalendar}
        ignoreValidation={true}
        isEnabled
        initialFocus={false}
        placeholders={{ startDate: "Check-in", endDate: "Check-out" }}
        month={
          availableFrom
            ? new Date(availableFrom).getMonth() + 1
            : new Date().getMonth() + 1
        }
        year={
          availableFrom
            ? new Date(availableFrom).getFullYear()
            : new Date().getFullYear()
        }
        sendValue={(dates) => {
          setAvailableFrom(dates?.startDate);
          setAvailableTo(dates?.startDate ? dates?.endDate : "");
          // if (dates?.startDate) {
          //   document.getElementById("endDate")?.click();
          // }
        }}
        initialDates={{ startDate: availableFrom, endDate: availableTo }}
        key={JSON.stringify(availableFrom)}
        isClearable={true}
        formatDayOfMonth="DD MMM YYYY"
        reset={Boolean(isAvailability)}
      />
      <NumberIncrementorDropDown
        icon="user"
        labelFormatter={displayGuestsSearch}
        my={0}
        name="maxGuests"
        options={guestOptions}
        key={JSON.stringify(guestOptions)}
        placeholder="No of guests"
        sendValue={({ value }: any) => {
          setAdults(value[0]);
          setChildren(value[1]);
          setGuests(value[0] + value[1]);
        }}
        testId="guests"
      />
      <WrapperButton isEnabled={isEnabled}>
        <Button
          label="Search"
          my={0}
          onClick={handleOnClick}
          styling={BUTTON_INLINE}
          testId="add new property"
          type={BUTTON_PRIMARY}
          isEnabled={isEnabled}
        />
      </WrapperButton>
    </Flex>
  );
};
