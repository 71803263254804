import "isomorphic-fetch";
import get from "lodash/get";

import {
  generateErrorHandler,
  NetworkError,
  UNAUTHORIZED,
} from "Utils/Network";
import { tagManager } from "Utils";
import { API_URL } from "Config";
import { propertiesCountRestore } from "Actions/properties/propertiesCountRestore";
import { SESSION } from "Constants/actionTypes";
import { setUser } from "ThirdParty";

const handleError = generateErrorHandler(SESSION.ERROR);

export function login(email: string, password: string, otp?: string) {
  return async (dispatch: (...args: any) => any) => {
    dispatch({ type: SESSION.PENDING, status: true });
    try {
      await getCSRF();
      const user = await validate(email, password, otp);
      if (!user) {
        dispatch({ type: SESSION.ERROR, errorCode: UNAUTHORIZED });
        return;
      }
      setUser(user);
      tagManager("login-details", { email: user?.email, roles: user?.roles });
      user.propertyStatus = await propertiesCountRestore();
      dispatch({ type: SESSION.SUCCESS, user });
    } catch (err: any) {
      dispatch(handleError(err));
    }
  };
}

const getCSRF = async () => {
  const response = await fetch(`${API_URL}v1/public/csrf/`, {
    credentials: "include",
    mode: "cors",
  });
  if (!response.ok) {
    throw new NetworkError(response);
  }
  // this response contains a `set-cookie` header that sets a cookie with name `madecomfy_session`
  await response.json();
};

const validate = async (email: string, password: string, otp?: string) => {
  const bodyData: any = {
    email,
    password,
  };
  // Add otp to bodyData only if it has a value
  if (otp) {
    bodyData.otp = otp;
  }
  const response = await fetch(`${API_URL}v3/auth?include=user`, {
    credentials: "include",
    mode: "cors",
    method: "POST",
    body: JSON.stringify(bodyData),
  });
  if (response.status === UNAUTHORIZED) {
    // it's a 401: just wrong username/password - don't throw an error.
    return null;
  }
  if (!response.ok) {
    throw new NetworkError(response);
  }
  const json = await response.json();
  const user = get(json, "included[0].attributes");
  return user;
};

export function resetLogin() {
  return (dispatch: (...args: any) => any) => {
    dispatch({ type: SESSION.RESET });
  };
}
