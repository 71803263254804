import "isomorphic-fetch";

import { generateErrorHandler, generateURL, NetworkError } from "Utils/Network";
import { API_URL } from "Config";
import { SEARCH_PROPERTY_QUERY } from "Constants/actionTypes";

const handleError = generateErrorHandler(SEARCH_PROPERTY_QUERY.ERROR);

let globalNonce: any = null;

export function propertiesRestore(params: { [key: string]: any } = {}) {
  const {
    //  includeInactive = true,
    pageSize = 26, // not sure why 26?
    pageNumber = 1,
    propertyStatus,
    query = undefined,
    sort = undefined,
    filter,
    filterAvailability,
    filterPriceRange,
  } = params;
  let nonce = (globalNonce = { params });

  return async (dispatch: (...args: any) => any) => {
    try {
      let url = generateURL(`${API_URL}v3/search`, {
        "page[number]": pageNumber,
        "page[size]": pageSize,
        "filters[type]": "property",
        "filters[query]": query,
        version: 2,
        "filters[propertyStatuses]": propertyStatus,
        ...(sort && { sort }),
        //  includeInactive: includeInactive || undefined,
      });
      if (filter) {
        url += "&" + filter;
      }
      if (filterAvailability) {
        url += "&" + filterAvailability;
      }
      if (filterPriceRange) {
        url += "&" + filterPriceRange;
      }
      dispatch({ type: SEARCH_PROPERTY_QUERY.RESET });
      dispatch({ type: SEARCH_PROPERTY_QUERY.PENDING, status: true });
      const response = await fetch(url, {
        mode: "cors",
        credentials: "include",
        method: "GET",
      });

      if (nonce !== globalNonce) {
        // this response is stale
        // multiple queries have been made in succession and an older call has resolved after a more recent one.
        dispatch({ type: "IGNORING_STALE_RESPONSE", params: nonce.params });
        return;
      }
      if (!response.ok) {
        throw new NetworkError(response);
      }
      const json = await response.json();

      const { data, meta } = json;
      const propertyList = data.map((property: { [key: string]: any }) => {
        const { attributes, id } = property;
        return {
          ...attributes,
          id,
        };
      });
      dispatch({
        type: SEARCH_PROPERTY_QUERY.SUCCESS,
        propertyList,
        minPrice: meta?.minPrice,
        maxPrice: meta?.maxPrice,
        totalCount: meta?.totalCount,
        totalPages: meta?.totalPages,
      });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("propertiesRestore error", err);
      dispatch(handleError(err));
    }
  };
  /* return async (dispatch: (...args: any) => any) => {
    try {
      const url = generateURL(`${API_URL}v3/properties`, {
        include: "address,amenities,details,resources",
        "page[number]": pageNumber,
        "page[size]": pageSize,
        search: query,
        includeInactive: includeInactive || undefined,
      });
      dispatch({ type: SEARCH_PROPERTY_QUERY.RESET });
      dispatch({ type: SEARCH_PROPERTY_QUERY.PENDING, status: true });
      const response = await fetch(url, {
        mode: "cors",
        credentials: "include",
        method: "GET",
      });

      if (nonce !== globalNonce) {
        // this response is stale
        // multiple queries have been made in succession and an older call has resolved after a more recent one.
        dispatch({ type: "IGNORING_STALE_RESPONSE", params: nonce.params });
        return;
      }
      if (!response.ok) {
        throw new NetworkError(response);
      }
      const json = await response.json();

      const { data, included, meta } = json;
      const propertyList = data.map((property: {[key: string]: any}) => {
        const { attributes, id, relationships } = property;
        const {address: propAddress, amenities: propAmenities, details: propDetails, resources: propResources} = relationships;
        delete attributes.address;
        const address = included.find(
          (property: {[key: string]: any}) => property.id === propAddress?.data?.id,
        )?.attributes;
        const amenities = included.find(
          (property: {[key: string]: any}) => property.id === propAmenities?.data?.id,
        )?.attributes?.amenities;
        const details = included.find(
          (property: {[key: string]: any}) => property.id === propDetails?.data?.id,
        )?.attributes;
        const resources = included.find(
          (property: {[key: string]: any}) => property.id === propResources?.data?.id,
        )?.attributes?.links;
        return {
          ...attributes,
          address,
          amenities,
          details,
          resources,
          id,
        };
      });
      dispatch({
        type: SEARCH_PROPERTY_QUERY.SUCCESS,
        propertyList,
        totalPages: meta?.totalPages,
      });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("propertiesRestore error", err);
      dispatch(handleError(err));
    }
  }; */
}

export function resetPropertiesRestore() {
  return (dispatch: (...args: any) => any) => {
    dispatch({ type: SEARCH_PROPERTY_QUERY.RESET });
  };
}
