// This component is reference from Fitzgerald's RangeSlider component
import { InputText, TYPE_NUMBER } from "@madecomfy/webooi";
import React, { useState, useRef, useEffect, useCallback } from "react";
import styled, { css } from "styled-components";

const THUMB_SIZE = 24;

const Title = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 32px;
`;

const Slider = styled.div<{ $isEnabled?: boolean }>`
  position: relative;
  min-width: 150px;
  padding: ${THUMB_SIZE * 0.5}px ${THUMB_SIZE}px;
  ${({ $isEnabled }) =>
    $isEnabled
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: no-drop;
          opacity: 0.5;
        `};
`;

const Railing = styled.div`
  background-color: #dfdfdf;
  border-radius: 36px;
  height: 16px;
  position: relative;
  margin-top: 4px;
`;

const Thumb = styled.div<{ $isEnabled?: boolean }>`
  background-color: ${({ theme }) => theme.background.brand};
  border-radius: 50%;
  height: ${THUMB_SIZE}px;
  position: absolute;
  top: 50%;
  /* transition: border-color 0.1s ease; */
  width: ${THUMB_SIZE}px;
  z-index: 2;
  ${({ $isEnabled }) =>
    $isEnabled &&
    css`
      cursor: pointer;
      :hover {
        background-color: ${({ theme }) => theme.navy};
      }
      :active {
        z-index: 3;
      }
    `}
`;

const Track = styled.div<{ $isAnimated?: boolean }>`
  background-color: #2192a380;
  height: 16px;
  position: absolute;
  top: 0px;
  ${({ $isAnimated }) =>
    $isAnimated &&
    css`
      transition: left 0.2s ease-in-out, right 0.2s ease-in-out;
    `}
`;

const Marker = styled.div`
  position: absolute;
  bottom: 100%;
  padding-top: 16px;
  font-size: 16px;
  color: #939296;
  transform: translateX(-50%);
  &:after {
    content: "";
    width: 1px;
    height: 8px;
    background: #dfdfdf;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const lerp = (a: any, b: any, position: any) => a + (b - a) * position;
const constrain = (value: any, min: any, max: any) =>
  Math.min(max, Math.max(value, min));

const MINIMUM = "min";
const MAXIMUM = "max";

interface IProps {
  isEnabled?: boolean;
  labelFormatter?: (v: any) => number;
  max: number;
  min: number;
  name?: string;
  roundTo?: number;
  sendOnChange?: () => void;
  sendValue?: (e: any) => void;
  value?: any;
}

export const PriceRangeSlider: React.FC<IProps> = (props) => {
  const {
    isEnabled = true,
    max,
    min,
    roundTo = 1,
    sendOnChange,
    sendValue,
    value: propValue = { min, max },
  } = props;
  const minValue = min / 100;
  const maxValue = max / 100;
  const [value, setValue] = useState(propValue);
  const [thumbPositions, setThumbPositions] = useState({
    min: (100 * (propValue.min - min)) / (max - min),
    max: (100 * (propValue.max - min)) / (max - min),
  });
  const activeThumbRef = useRef<"min" | "max" | null>(null);
  const [activeOffset, setActiveOffset] = useState(0);
  const boundsElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sendValue) {
      sendValue({ value, isValid: true });
    }
    setThumbPositions({
      min: (100 * (value.min - min)) / (max - min),
      max: (100 * (value.max - min)) / (max - min),
    });
  }, [value]);

  useEffect(() => {
    setValue(propValue);
    setThumbPositions({
      min: (100 * (propValue.min - min)) / (max - min),
      max: (100 * (propValue.max - min)) / (max - min),
    });
  }, [min, max, propValue?.min, propValue?.max]);

  const getThumbOffset = (thumbId: any) => {
    if (thumbId === MAXIMUM) {
      return -activeOffset;
    }
    if (thumbId === MINIMUM) {
      return THUMB_SIZE - activeOffset;
    }
    return 0;
  };

  const getActiveThumb = (currentValue: any) => {
    if (activeThumbRef.current) {
      return activeThumbRef.current;
    }
    const diffMax = Math.abs(value.max - currentValue);
    const diffMin = Math.abs(value.min - currentValue);
    return diffMax < diffMin ? MAXIMUM : MINIMUM;
  };

  const setValues = (clientX = 0, leftBound: any, width: any) => {
    const offsetX = getThumbOffset(activeThumbRef.current);
    const x = constrain(clientX - leftBound + offsetX, 0, width);
    const ratio = constrain(x / width, 0, 1);
    let currentValue = lerp(min, max, ratio);
    currentValue = Math.round(currentValue / roundTo) * roundTo;

    const thumbId = getActiveThumb(currentValue);
    const newThumbPositions = { ...thumbPositions };
    const newValue = { ...value };

    if (thumbId === MINIMUM && currentValue >= value.max) {
      currentValue = value.max;
      newThumbPositions[thumbId] = thumbPositions.max;
    } else if (thumbId === MAXIMUM && currentValue <= value.min) {
      currentValue = value.min;
      newThumbPositions[thumbId] = thumbPositions.min;
    } else {
      newThumbPositions[thumbId] = ratio * 100;
    }

    newValue[thumbId] = currentValue;
    setValue(newValue);
    setThumbPositions(newThumbPositions);

    if (sendOnChange && sendValue) {
      sendValue({ isValid: true, value: newValue });
    }
  };

  const handleDragUpdate = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const bounds = boundsElement.current?.getBoundingClientRect();
    const clientX =
      typeof event.clientX !== "undefined"
        ? event.clientX
        : event.touches && event.touches[0].clientX;
    setValues(clientX, bounds?.left, bounds?.width);
  };

  const handleDragStart = (event: any) => {
    if (!isEnabled) return;
    document.addEventListener("mousemove", handleDragUpdate);
    document.addEventListener("touchmove", handleDragUpdate);
    document.addEventListener("mouseup", handleDragStop);
    document.addEventListener("touchend", handleDragStop);
    handleDragUpdate(event);
  };

  const handleDragStop = () => {
    document.removeEventListener("mousemove", handleDragUpdate);
    document.removeEventListener("touchmove", handleDragUpdate);
    document.removeEventListener("mouseup", handleDragStop);
    document.removeEventListener("touchend", handleDragStop);
    activeThumbRef.current = null;
  };

  const createThumbStartHandler = (thumbId: any) => (event: any) => {
    if (!isEnabled) return;
    activeThumbRef.current = thumbId;
    setActiveOffset(event.nativeEvent.offsetX || THUMB_SIZE / 2);
    handleDragStart(event);
  };

  const handleThumbMinStart = createThumbStartHandler(MINIMUM);
  const handleThumbMaxStart = createThumbStartHandler(MAXIMUM);

  const calculateOffsetMarker = () => {
    const range = maxValue - minValue;
    const offset = range / 3;
    return offset;
  };

  const renderMarkers = useCallback(() => {
    if (!min || !max) return <></>;
    const offset = calculateOffsetMarker();
    const markers = [];

    // Push first marker at 'min' position
    markers.push(
      <Marker key={min} style={{ left: THUMB_SIZE }}>
        ${minValue}
      </Marker>,
    );

    const firstMarkerValue = minValue + offset;
    const secondMarkerValue = minValue + 2 * offset;

    const firstPosition =
      ((firstMarkerValue - minValue) / (maxValue - minValue)) * 100;
    const secondPosition =
      ((secondMarkerValue - minValue) / (maxValue - minValue)) * 100;

    // Push the first marker between
    markers.push(
      <Marker key={firstMarkerValue} style={{ left: `${firstPosition}%` }}>
        ${firstMarkerValue.toFixed(0)}
      </Marker>,
    );

    // Push the second marker between
    markers.push(
      <Marker key={secondMarkerValue} style={{ left: `${secondPosition}%` }}>
        ${secondMarkerValue.toFixed(0)}
      </Marker>,
    );

    // Marker at 'max' position
    markers.push(
      <Marker key={max} style={{ right: -THUMB_SIZE * 2.1 }}>
        ${maxValue}
      </Marker>,
    );

    return markers;
  }, [min, max, calculateOffsetMarker]);

  return (
    <div>
      <Title>Price range</Title>
      <Slider
        data-test="price-range-slider"
        $isEnabled={isEnabled}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
      >
        {renderMarkers()}
        <Railing ref={boundsElement}>
          <Track
            $isAnimated={activeThumbRef.current === null}
            style={{
              left: `calc(${thumbPositions.min}% - 2px)`,
              right: `calc(${100 - thumbPositions.max}% - 2px)`,
            }}
          />
          <Thumb
            data-test="price-range-slider-min-thumb"
            $isEnabled={isEnabled}
            onMouseDown={handleThumbMinStart}
            onTouchStart={handleThumbMinStart}
            style={{
              left: `calc(${thumbPositions.min}% - ${THUMB_SIZE * 0.5}px)`,
              transform: `translate(-50%, -50%)`,
            }}
          />
          <Thumb
            data-test="price-range-slider-max-thumb"
            $isEnabled={isEnabled}
            onMouseDown={handleThumbMaxStart}
            onTouchStart={handleThumbMaxStart}
            style={{
              left: `calc(${thumbPositions.max}% - ${THUMB_SIZE * 0.5}px)`,
              transform: `translate(50%, -50%)`,
            }}
          />
        </Railing>
      </Slider>
      <InputWrapper>
        <InputText
          value={value?.min / 100}
          icon="currency"
          name="min"
          sendValue={({
            value,
            isValid,
          }: {
            value: number;
            isValid: boolean;
          }) => {
            if (isValid) {
              setValue((prevValue: any) => ({
                ...prevValue,
                min: Number(value),
              }));
            }
          }}
          testId="min-input"
          type={TYPE_NUMBER}
          min={min}
          validation={{
            check: (v: any) => {
              return (
                !isNaN(parseFloat(v)) &&
                isFinite(v) &&
                Number(v) <= value?.max &&
                Number(v) >= min
              );
            },
          }}
          key={value?.max}
          data-test="test"
        />
        <InputText
          value={value?.max / 100}
          icon="currency"
          name="max"
          sendValue={({
            value,
            isValid,
          }: {
            value: number;
            isValid: boolean;
          }) => {
            if (isValid) {
              setValue((prevValue: any) => ({
                ...prevValue,
                max: Number(value),
              }));
            }
          }}
          testId="max-input"
          type={TYPE_NUMBER}
          min={min}
          validation={{
            check: (v: any) => {
              return (
                !isNaN(parseFloat(v)) &&
                isFinite(v) &&
                Number(v) >= value?.min &&
                Number(v) <= max
              );
            },
          }}
          key={value?.min * -1}
        />
      </InputWrapper>
    </div>
  );
};
