import React from "react";
import styled from "styled-components";

import { H2 } from "Components/Typography";
import {
  Button,
  BUTTON_INLINE,
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  BUTTON_SMALL,
} from "@madecomfy/webooi";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #efefef;

  h2 {
    margin: 0;
    padding: 24px;
  }
`;

const ImageList = styled.div`
  margin: 0;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  img {
    border-radius: 8px;
    object-fit: cover;
  }

  img:first-child {
    width: 100%;
    height: auto;
  }

  img:not(:first-child) {
    flex: 1 1 calc((100% - 24px) / 2);
    max-width: calc((100% - 24px) / 2);
  }
`;

const Footer = styled.div`
  margin: 0;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #efefef;

  button {
    margin: 0;
  }
`;

interface IProps {
  propertyId: string;
  photos: any[];
  onCancel: () => void;
  isEditor: boolean;
}

export const PropertyImagePopup: React.FC<IProps> = (props) => {
  const { propertyId, photos, onCancel, isEditor } = props;
  const history = useHistory();

  const handleEditPhoto = () => {
    history.push(`/properties/${propertyId}/details/photos`);
  };

  return (
    <Wrapper data-test="property-image-popup">
      <TitleWrapper>
        <Title>
          <H2>Photos</H2>
        </Title>
        <ImageList>
          {photos?.map((photo) => (
            <img key={photo?.url} alt="photo" src={photo?.url} />
          ))}
        </ImageList>
      </TitleWrapper>
      {isEditor && (
        <Footer>
          <Button
            label="Back"
            my={0}
            size={BUTTON_SMALL}
            styling={BUTTON_INLINE}
            testId="back"
            type={BUTTON_SECONDARY}
            onClick={onCancel}
          />
          <Button
            label="Edit Photo"
            my={0}
            size={BUTTON_SMALL}
            styling={BUTTON_INLINE}
            testId="edit"
            type={BUTTON_PRIMARY}
            onClick={handleEditPhoto}
          />
        </Footer>
      )}
    </Wrapper>
  );
};
