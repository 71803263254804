import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  ButtonSet,
  CheckBox,
  Drawer,
  NumberIncrementor,
} from "@madecomfy/webooi";

import MultiSelect from "Components/MultiSelect";

import { H3 } from "Components/Typography";
import { ownersRestore } from "Actions/users/usersRestore";
import { Stars } from "Containers/Booking/BookingReview/Review";
import { userCompanies } from "Actions/users/userCompanies";
import { PriceRangeSlider } from "./PriceRangeSlider";

const Wrapper = styled.div``;
const WrapperFilter = styled.div`
  h3 {
    margin-top: -24px;
    @media only screen and (max-width: 500px) {
      margin-top: -16px;
    }
  }
  div {
    label:first-child {
      margin-bottom: 16px;
      font-weight: 700;
      line-height: 22px;
    }
  }
`;

const WrapperDrawer = styled.div`
  div[data-test="drawer"] {
    @media (min-width: 475px) {
      width: 450px;
    }
  }
  div > div + button {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    align-items: baseline;
    background: #f4f4f5;
    color: #303336;
    padding: 8px 32px;
    height: 40px;
    border-radius: 4px;
    :focus {
      text-decoration: none;
      color: #303336;
    }
    svg {
      fill: #303336 !important;
      viewbox: "0 0 24 24";
    }
    :hover {
      background: #e9e9ea;
      color: #303336;
    }
  }
  svg:first-child {
    width: 16px;
    height: 16px;
    margin-right: 0 !important;
  }
  div[data-test="checkbox-amenities"] {
    column-count: 2;
  }
  div[data-test="drawer-content"] {
    > div:first-child {
      width: auto;
    }
  }
  span:after {
    top: 2px;
    height: 10px;
    left: 6px;
  }
`;
const Line = styled.p`
  border-top: 1px solid #efefef;
  margin-top: 24px;
  margin-bottom: 24px;
`;
const LineSpacer = styled(Line)`
  margin: 24px -24px 24px -24px;
`;
const Label = styled.label`
  font-size: 16px;
  color: #303336;
  display: block;
`;
const WrapperButtonSet = styled.div`
  button {
    margin-right: 16px;
    height: 48px;
    padding: 0 16px;
    border: 1px solid #cbc9cb;
  }
`;
const Star = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    margin: 0;
  }
`;
const MinNight = styled.div`
  @media (min-width: 475px) {
    > div {
      width: 48%;
    }
  }
`;

export const Filter = ({
  isLicensee,
  isOwner,
  setFilter,
  isPriceRange,
  minPrice,
  maxPrice,
  selectedMinPrice,
  selectedMaxPrice,
  setSelectedMinPrice,
  setSelectedMaxPrice,
  setFilterPriceRange,
}: any) => {
  const searchParams = new URLSearchParams(location.search);

  const [filterNumber, setFilterNumber] = useState(0);
  const originalCleaningStatus = [
    { name: "Ready", id: "ready" },
    { name: "Occupied", id: "occupied" },
    { name: "Dirty", id: "dirty" },
    { name: "In-progress", id: "in-progress" },
    { name: "Blocked", id: "blocked" },
  ];
  const [cleaningStatus, setCleaningStatus] = useState([
    ...originalCleaningStatus,
  ]);
  const [owner, setOwner] = useState([]);
  const [partner, setPartner] = useState([]);
  const [partnersList, setPartnersList] = useState(
    partner && partner.length > 0 ? [...partner] : [],
  );

  const optionsMap = new Map<string, string[]>([
    ["propertyStatuses", ["onboarding", "active", "offboarding", "inactive"]],
    ["propertySegments", ["luxe", "premium", "comfort", "essentials"]],
    ["keyTypes", ["standard", "key-nest", "convenience-store", "key-cafe"]],
    ["managementTypes", ["madecomfy", "pro-partner"]],
    [
      "keyStatuses",
      [
        "Unknown",
        "madecomfy",
        "In Use",
        "In Store",
        "Key in place",
        "Cleaner confirmed key is in place",
        "Waiting for drop off",
      ],
    ],
    [
      "amenities",
      [
        "wireless-internet, pocket-wifi, internet",
        "air-conditioning",
        "street-parking, disabled-parking-spot, paid-parking-off-premises, free-parking-on-premises",
        "swimming-pool, outdoor-pool, indoor-pool, private-pool",
        "hot-tub",
        "central-heating",
        "fireplace",
        "kitchen",
        "washer",
        "balcony",
        "garden-or-backyard",
        "bbq-grill",
      ],
    ],
  ]);
  const [activeValues, setActiveValues] = useState<{ [key: string]: any }>({});
  const [values, setValues] = useState<{ [key: string]: any }>({});
  const [resetVal, setResetVal] = useState(false);
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const valueRatings: { [key: string]: boolean } = {
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
    "at-risk": false,
    Suspended: false,
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (isShowDrawer) {
      getActiveFilters();
    }
  }, [isShowDrawer]);

  const getCompanies = async () => {
    const companies = await userCompanies("licensee");
    companies?.forEach((obj: any) => {
      obj.name = obj?.companyName;
      delete obj?.companyName;
      delete obj?.partnerType;
    });
    setPartner(companies);
    return companies;
  };

  const [querySegments, setQuerySegments] = useState<{ [key: string]: string }>(
    {},
  );

  const getFilterValues = (value: any) => {
    let filterValues;
    if (
      value === 0 ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" &&
        Object.keys(value).filter((key) => value[key]).length === 0)
    ) {
      // If value is 0, remove the queryStringSegment
      filterValues = undefined;
    } else {
      filterValues = Array.isArray(value)
        ? value.join(",")
        : typeof value === "object"
        ? Object.keys(value)
            .filter((key) => value[key])
            .join(",")
        : value;
    }
    return filterValues;
  };

  const handleFilter = (value: any, name: string): void => {
    if (
      name !== "airbnbOverallRatings" &&
      name !== "riskStatuses" &&
      name !== "cleaningStatuses" &&
      name !== "owners" &&
      name !== "companies" &&
      name !== "minPrice" &&
      name !== "maxPrice"
    ) {
      setValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
    // Construct query string segment for the specified filter type
    const filterValues = getFilterValues(value);
    if (filterValues !== undefined) {
      const queryStringSegment = `filters[${name}]=${filterValues}`;
      // Update the querySegments object
      setQuerySegments((prevSegments) => ({
        ...prevSegments,
        [name]: queryStringSegment,
      }));
    } else {
      // Remove the queryStringSegment from querySegments object
      setQuerySegments((prevSegments) => {
        const { [name]: _, ...rest } = prevSegments;
        return rest;
      });
    }
  };

  const resetFilter = () => {
    setValues({ minPrice: minPrice, maxPrice: maxPrice });
    setResetVal(true);
  };

  const getActiveFilters = () => {
    setValues(activeValues);
    setCleaningStatus(originalCleaningStatus);
    setPartnersList(partner && partner.length > 0 ? [...partner] : []);
  };

  const transformValue = (key: string, values: string) => {
    if (["minimumStay", "bedrooms", "bathrooms"].includes(key)) {
      return Number(values);
    }

    const options = optionsMap.get(key) || [
      "house,studio,townhouse",
      "apartment",
    ];

    return options.reduce((acc, option) => {
      const value = values.split(",");

      if (option.includes(",")) {
        const groupOptions = option.split(",");
        acc[option] = groupOptions.some((groupOption) =>
          value.includes(groupOption),
        );
      } else {
        acc[option] = value.includes(option);
      }

      return acc;
    }, {} as { [key: string]: boolean });
  };

  const transformCleaningStatus = (value: string) => {
    const statusIds = value.split(",");

    return originalCleaningStatus.filter((status) =>
      statusIds.includes(status.id),
    );
  };

  const processOwnerParams = async (value: string) => {
    const owners = await ownersRestore(1, 10, undefined, "ROLE_OWNER");
    if (owners) {
      const ids = value.split(",");

      const filteredOwners = owners.filter((owner: any) =>
        ids.includes(owner.id),
      );

      handleGenericFilter("owners", filteredOwners);
    }
  };

  const processPartnerParams = async (value: string) => {
    const companies = await getCompanies();
    if (companies) {
      const ids = value.split(",");

      const filteredPartners = companies.filter((partner: any) =>
        ids.includes(partner.id),
      );

      handleGenericFilter("companies", filteredPartners);
    }
  };

  const handleGenericFilter = (key: string, value: any) => {
    switch (key) {
      case "owners":
      case "companies":
      case "cleaningStatuses":
        const data = value.map((item: any) => item.id);
        handleFilter(data, key);
        setValues((prevValues) => ({ ...prevValues, [key]: value }));
        break;

      case "airbnbOverallRatings":
      case "riskStatuses":
        setValues((prevValues) => ({ ...prevValues, ratings: value }));
        const selectedKeys = Object.keys(value).filter((k) => value[k]);
        const numericValues = selectedKeys.filter((val) =>
          ["1", "2", "3", "4", "5"].includes(val),
        );
        const riskStatusValues = selectedKeys.filter(
          (val) => val === "at-risk" || val === "Suspended",
        );
        handleFilter(riskStatusValues, "riskStatuses");
        handleFilter(numericValues, "airbnbOverallRatings");
        break;

      default:
        const transformedValue = transformValue(key, value);
        handleFilter(transformedValue, key);
    }
  };

  const handleFilterParams = React.useCallback(async () => {
    const promises = Array.from(searchParams).map(async ([key, value]) => {
      switch (key) {
        case "minPrice":
        case "maxPrice":
        case "filterText":
        case "sort":
        case "availableFrom":
        case "availableTo":
          break;

        case "companies":
          await processPartnerParams(value);
          break;

        case "owners":
          await processOwnerParams(value);
          break;

        case "cleaningStatuses":
          const transformedCleaningStatus = transformCleaningStatus(value);
          handleGenericFilter("cleaningStatuses", transformedCleaningStatus);
          break;

        case "airbnbOverallRatings":
        case "riskStatuses":
          const valuesArray = value.split(",");
          valuesArray.forEach((val) => {
            if (val in valueRatings) {
              valueRatings[val] = true;
            }
          });
          handleGenericFilter("airbnbOverallRatings", valueRatings);
          break;

        default:
          handleGenericFilter(key, value);
      }
    });

    await Promise.all(promises);
    const queryString = Object.values(querySegments).join("&");
    setFilter(queryString);
    setFilterNumber(Object.keys(querySegments).length);
    setActiveValues(values);
  }, [location.search]);

  useEffect(() => {
    handleFilterParams();
  }, [location.search]);

  return (
    <Wrapper>
      <WrapperDrawer>
        <Drawer
          icon={"lines" as any}
          label={`Filter ${filterNumber > 0 ? `(${filterNumber})` : ""}`}
          content={
            <WrapperFilter data-test="filter-wrap">
              <H3>Filters</H3>
              <LineSpacer />
              {isPriceRange && (
                <>
                  <PriceRangeSlider
                    max={maxPrice}
                    min={minPrice}
                    sendValue={({ value }: any) => {
                      setSelectedMinPrice(value?.min);
                      setSelectedMaxPrice(value?.max);
                    }}
                    {...(!!selectedMinPrice &&
                      !!selectedMaxPrice && {
                        value: {
                          min: selectedMinPrice,
                          max: selectedMaxPrice,
                        },
                      })}
                  />
                  <Line style={{ marginTop: 0 }} />
                </>
              )}
              <CheckBox
                label="Property type"
                name="propertyTypes"
                options={[
                  {
                    value: "house,studio,townhouse",
                    label: "House",
                  },
                  {
                    value: "apartment",
                    label: "Apartment",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "propertyTypes");
                }}
                value={values?.propertyTypes}
                testId="propertyTypes"
              />
              <Line />
              <MinNight>
                <NumberIncrementor
                  defaultValue={0}
                  label="Min nights"
                  labelFormatter={(num) =>
                    num === 0 || (Array.isArray(num) && num.length === 0)
                      ? "Any"
                      : num
                  }
                  max={99}
                  min={0}
                  name="minimumStay"
                  sendValue={({ value }: any) => {
                    handleFilter(value, "minimumStay");
                  }}
                  value={values?.minimumStay || 0}
                  testId="minimumStay"
                />
              </MinNight>
              <Line />
              <WrapperButtonSet>
                <ButtonSet
                  label="Bedrooms"
                  options={[
                    { value: "0", label: "Any" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4+" },
                  ]}
                  sendValue={({ value }: any) => {
                    handleFilter(Number(value), "bedrooms");
                  }}
                  value={String(values?.bedrooms ?? "0")}
                />
              </WrapperButtonSet>
              <Line />
              <WrapperButtonSet>
                <ButtonSet
                  label="Bathrooms"
                  options={[
                    { value: "0", label: "Any" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4+" },
                  ]}
                  sendValue={({ value }: any) => {
                    handleFilter(Number(value), "bathrooms");
                  }}
                  value={String(values?.bathrooms ?? "0")}
                />
              </WrapperButtonSet>
              <Line />
              {!isPriceRange && (
                <>
                  <CheckBox
                    label="Property status"
                    name="propertyStatuses"
                    options={[
                      {
                        value: "onboarding",
                        label: "Onboarding",
                      },
                      {
                        value: "active",
                        label: "Active",
                      },
                      {
                        value: "offboarding",
                        label: "Offboarding",
                      },
                      {
                        value: "inactive",
                        label: "Inactive",
                      },
                    ]}
                    sendValue={({ value }: any) => {
                      handleFilter(value, "propertyStatuses");
                    }}
                    value={values?.propertyStatuses}
                    testId="propertyStatuses"
                  />
                  <Line />
                </>
              )}
              <CheckBox
                label="Property segments"
                name="propertySegments"
                options={[
                  {
                    value: "luxe",
                    label: "Luxe",
                  },
                  {
                    value: "premium",
                    label: "Premium",
                  },
                  {
                    value: "comfort",
                    label: "Comfort",
                  },
                  {
                    value: "essentials",
                    label: "Essentials",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "propertySegments");
                }}
                value={values?.propertySegments}
                testId="proeprtySegments"
              />
              <Line />
              <CheckBox
                label="Ratings"
                name="airbnbOverallRatings"
                options={[
                  {
                    value: "1",
                    label: (
                      <Star>
                        <p>1</p>
                        <Stars rating="1" />
                      </Star>
                    ),
                  },
                  {
                    value: "2",
                    label: (
                      <Star>
                        <p>2</p>
                        <Stars rating="2" />
                      </Star>
                    ),
                  },
                  {
                    value: "3",
                    label: (
                      <Star>
                        <p>3</p>
                        <Stars rating="3" />
                      </Star>
                    ),
                  },
                  {
                    value: "4",
                    label: (
                      <Star>
                        <p>4</p>
                        <Stars rating="4" />
                      </Star>
                    ),
                  },
                  {
                    value: "5",
                    label: (
                      <Star>
                        <p>5</p>
                        <Stars rating="5" />
                      </Star>
                    ),
                  },
                  {
                    value: "at-risk",
                    label: "Airbnb at risk",
                  },
                  {
                    value: "Suspended",
                    label: "Airbnb suspended",
                  },
                ]}
                sendValue={({ value }: any) => {
                  setValues({ ...values, ratings: value });
                  const newVal = Object.keys(value).filter((key) => value[key]);
                  // Filter numeric values (1, 2, 3, 4, 5) for airbnbOverallRatings
                  const numericValues = newVal.filter((val) =>
                    ["1", "2", "3", "4", "5"].includes(val),
                  );

                  // Filter "at-risk" and "Suspended" for riskStatuses
                  const riskStatusesValues = newVal.filter(
                    (val) => val === "at-risk" || val === "Suspended",
                  );
                  handleFilter(riskStatusesValues, "riskStatuses");
                  handleFilter(numericValues, "airbnbOverallRatings");
                }}
                testId="airbnbOverallRatings"
                value={values?.ratings || ""}
              />
              <Line />
              {!isPriceRange && (
                <>
                  <div>
                    <Label>Cleaning status</Label>
                    <MultiSelect
                      existingItems={values?.cleaningStatuses ?? []}
                      handleTypeAhead={(val) => {
                        if (val.trim() === "") {
                          setCleaningStatus([...originalCleaningStatus]);
                        } else {
                          const filteredStatus = originalCleaningStatus.filter(
                            ({ name }) =>
                              name.toLowerCase().includes(val.toLowerCase()),
                          );
                          setCleaningStatus(filteredStatus);
                        }
                      }}
                      placeholder="Please select"
                      searchResult={cleaningStatus}
                      sendValue={(value) => {
                        const data = value.map((item: any) => item.id);
                        handleFilter(data, "cleaningStatuses");
                        setValues({ ...values, cleaningStatuses: value });
                      }}
                      reset={resetVal}
                      setResetVal={setResetVal}
                      testId="cleaning-status"
                    />
                  </div>
                  <Line />
                </>
              )}
              <CheckBox
                label="Key type"
                name="propertyType"
                options={[
                  {
                    value: "standard",
                    label: "Standard",
                  },
                  {
                    value: "key-nest",
                    label: "KeyNest",
                  },
                  {
                    value: "convenience-store",
                    label: "Convenience store",
                  },
                  {
                    value: "key-cafe",
                    label: "KeyCafe",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "keyTypes");
                }}
                value={values?.keyTypes}
                testId="keyType"
              />
              <Line />
              {!isPriceRange && (
                <>
                  <CheckBox
                    label="Key status"
                    name="keyStatus"
                    options={[
                      {
                        value: "Unknown",
                        label: "Unknown",
                      },
                      {
                        value: "In Use",
                        label: "In use",
                      },
                      {
                        value: "In Store",
                        label: "In store",
                      },
                      {
                        value: "Key in place",
                        label: "In place",
                      },
                      {
                        value: "Cleaner confirmed key is in place",
                        label: "In place (verification required)",
                      },
                      {
                        value: "Waiting for drop off",
                        label: "Waiting for drop off",
                      },
                    ]}
                    sendValue={({ value }: any) => {
                      handleFilter(value, "keyStatuses");
                    }}
                    value={values?.keyStatuses}
                    testId="keyStatus"
                  />
                  <Line />
                </>
              )}
              {!isLicensee && (
                <>
                  <CheckBox
                    label="Management"
                    name="managementTypes"
                    options={[
                      {
                        value: "madecomfy",
                        label: "Madecomfy",
                      },
                      {
                        value: "pro-partner",
                        label: "Partner",
                      },
                    ]}
                    sendValue={({ value }: any) => {
                      handleFilter(value, "managementTypes");
                    }}
                    value={values?.managementTypes}
                    testId="managementTypes"
                  />
                  <Line />
                </>
              )}
              {!isOwner && (
                <>
                  {!isLicensee && (
                    <>
                      <div>
                        <Label>Partner</Label>
                        <MultiSelect
                          existingItems={values?.companies || []}
                          handleTypeAhead={async (val) => {
                            if (partner) {
                              if (val.trim() === "") {
                                setPartnersList([...partner]);
                              } else {
                                const filteredStatus = partner.filter(
                                  ({ name }: any) =>
                                    name
                                      .toLowerCase()
                                      .includes(val.toLowerCase()),
                                );
                                setPartnersList(filteredStatus);
                              }
                            }
                          }}
                          placeholder="Please select"
                          searchResult={
                            partnersList && partnersList.length > 0
                              ? partnersList
                              : []
                          }
                          sendValue={(value: any) => {
                            const data = value.map((item: any) => item.id);
                            handleFilter(data, "companies");
                            setValues({ ...values, companies: value });
                          }}
                          reset={resetVal}
                          setResetVal={setResetVal}
                          testId="partner"
                        />
                      </div>
                      <Line />
                    </>
                  )}

                  <div>
                    <Label>Owner</Label>
                    <MultiSelect
                      existingItems={values?.owners || []}
                      handleTypeAhead={async (val) => {
                        const owners = await ownersRestore(
                          1,
                          10,
                          val,
                          "ROLE_OWNER",
                        );
                        if (owners) {
                          setOwner(owners);
                        }
                      }}
                      placeholder="Please select"
                      searchResult={owner}
                      sendValue={(value: any) => {
                        const data = value.map((item: any) => item.id);
                        handleFilter(data, "owners");
                        setValues({ ...values, owners: [...value] });
                      }}
                      reset={resetVal}
                      setResetVal={setResetVal}
                      testId="owners"
                    />
                  </div>
                  <Line />
                </>
              )}
              <div>
                <Label>Amenities</Label>
                <CheckBox
                  name="amenities"
                  options={[
                    {
                      value: "wireless-internet, pocket-wifi, internet",
                      label: "WiFi",
                    },
                    { value: "air-conditioning", label: "Air conditioning" },
                    {
                      value:
                        "street-parking, disabled-parking-spot, paid-parking-off-premises, free-parking-on-premises",
                      label: "Parking",
                    },
                    {
                      value:
                        "swimming-pool, outdoor-pool, indoor-pool, private-pool",
                      label: "Pool",
                    },
                    { value: "hot-tub", label: "Hot tub" },
                    { value: "central-heating", label: "Heating" },
                    { value: "fireplace", label: "Fireplace" },
                    { value: "kitchen", label: "Kitchen" },
                    { value: "washer", label: "Washing machine" },
                    { value: "balcony", label: "Balcony" },
                    { value: "garden-or-backyard", label: "Garden" },
                    { value: "bbq-grill", label: "BBQ" },
                  ]}
                  sendValue={({ value }: any) => {
                    handleFilter(value, "amenities");
                  }}
                  value={values?.amenities}
                  testId="amenities"
                />
              </div>
            </WrapperFilter>
          }
          textSave="Apply"
          textCancel="Clear All"
          closeOnCancel={false}
          onCancel={resetFilter}
          onOpen={() => setIsShowDrawer(true)}
          onClose={() => setIsShowDrawer(false)}
          onSave={async () => {
            const queryString = Object.values(querySegments).join("&");
            setFilter(queryString);
            if (selectedMinPrice && selectedMaxPrice) {
              setFilterPriceRange(
                `filters[minPrice]=${selectedMinPrice}&filters[maxPrice]=${selectedMaxPrice}`,
              );
            }
            setFilterNumber(Object.keys(querySegments).length);
            setActiveValues(values);
          }}
        />
      </WrapperDrawer>
    </Wrapper>
  );
};
