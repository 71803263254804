import React, { useState } from "react";
import styled from "styled-components";

import logoInverse from "Containers/Welcome/assets/logoInverse.svg";
import {
  Alert,
  ALERT_ERROR,
  Button,
  BUTTON_CTA,
  BUTTON_LINK,
  InputText,
  TYPE_TEXT,
} from "@madecomfy/webooi";
import { Body, H2 } from "Components/Typography";
import {
  BodyWrapper,
  FormWrapper,
  LogoInv,
} from "Containers/Welcome//components/Elements";
import { UNAUTHORIZED } from "Utils/Network";

const WelcomeBackground = styled.div`
  background-attachment: fixed;
  background-color: #346b82;
  background-image: url("/img/gradient-bg.jpg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 1500px 450px;
  height: 350px;
  position: absolute;
  width: 100%;
`;
const VerifyWrapper = styled.div`
  margin: 0px auto;
  max-width: 466px;
  min-height: 500px;
  position: relative;
  text-align: center;
  width: 100%;
  padding: 0px 24px;
  button:last-child {
    color: ${({ theme }) => theme.text.subtle};
  }
`;

interface IProps {
  buttonLabel: string;
  error?: any;
  handleOtp: (...args: any) => any;
  handleShowVerify: (...args: any) => any;
}
const Verify: React.FC<IProps> = ({
  buttonLabel,
  error,
  handleOtp,
  handleShowVerify,
}) => {
  const [otp, setOtp] = useState("");
  return (
    <>
      <WelcomeBackground />
      <VerifyWrapper>
        <LogoInv src={logoInverse} />
        <H2 white>Verify your identity</H2>
        <BodyWrapper>
          <Body color="#fff">A code was just sent to you by email.</Body>
        </BodyWrapper>
        <FormWrapper>
          {error === UNAUTHORIZED || error === "401" ? (
            <Alert
              message={"Invalid or expired code. Please try again."}
              type={ALERT_ERROR}
            />
          ) : null}
          <InputText
            label="Verification code"
            my={0}
            name="otp"
            placeholder="Enter the code"
            sendValue={({ value }: any) => setOtp(value)}
            testId="verification-code"
            type={TYPE_TEXT}
            validation={{
              check: (value: any) => value.length === 0 || value.length > 1,
              errorClient: "Enter a valid code",
            }}
          />
          <Button
            label="Verify"
            type={BUTTON_CTA}
            onClick={() => {
              if (!otp.length) return;
              handleOtp(otp);
            }}
          />
          <Button
            type={BUTTON_LINK}
            onClick={() => {
              setOtp("");
              handleShowVerify(false);
            }}
            label={buttonLabel}
            my={0}
          />
        </FormWrapper>
      </VerifyWrapper>
    </>
  );
};

export default Verify;
