import React, { Component } from "react";
import styled from "styled-components";

import { ALERT_ERROR, Alert, BUTTON_PRIMARY } from "@madecomfy/webooi";

import ComfyContainer from "Components/ComfyContainer";
import EditSettings from "./components/EditSettings";
import Loading from "Components/Loading";
import Verify from "./components/Verify";
import { H4 } from "Components/Typography";
import { PRECONDITION_FAILED, UNAUTHORIZED } from "Utils/Network";

interface IProps {
  history: any;
  match: { [key: string]: any };
  resetGet: (...args: any) => any;
  users: { [key: string]: any };
  userMeRestore: (...args: any) => any;
  userMeUpdate: (...args: any) => any;
  userRolesRestore: (...args: any) => any;
}

const Wrapper = styled(ComfyContainer)`
  margin: 24px;
`;

class Settings extends Component<IProps, any> {
  state = { otp: "", showVerify: false, updatedUser: {} };
  componentDidUpdate() {
    if (
      this.props?.users?.editUserStatus?.errorCode &&
      !this.state.showVerify
    ) {
      this.setState({ showVerify: true });
    }
  }
  resetState = () => {
    this.setState({ otp: "", showVerify: false, updatedUser: {} });
  };
  render() {
    const { resetGet, users, userMeRestore } = this.props;
    const { editUserPermissions, editUserStatus } = users;
    const { pending, roles: editUserRoles } = editUserPermissions;
    const {
      connectionError,
      error,
      errorCode,
      errorMessage,
      user,
      ownedCompanies,
      ownedProperties,
    } = editUserStatus;
    const canUpdateUser = user?.canUpdate;
    const { otp, showVerify } = this.state;
    if (error || connectionError) {
      // eslint-disable-next-line
      console.warn("Settings error:", error, connectionError);
      if (
        ((errorCode === UNAUTHORIZED || errorCode === "401") &&
          otp &&
          showVerify) ||
        ((errorCode === PRECONDITION_FAILED || errorCode === "412") &&
          showVerify)
      ) {
        return (
          <Verify
            buttonLabel="Back to dashboard"
            error={errorCode}
            handleOtp={(val) => {
              this.setState({ otp: val }, () => {
                const { otp, updatedUser } = this.state;
                const { userMeUpdate } = this.props;
                const updatedUserWithOtp = {
                  ...updatedUser,
                  otp: otp,
                };
                userMeUpdate(updatedUserWithOtp);
                resetGet();
                userMeRestore();
                this.setState({ showVerify: false });
              });
            }}
            handleShowVerify={() => {
              resetGet();
              userMeRestore();
              this.resetState(); // Reset the state when going back
            }}
          />
        );
      }
      return (
        <ComfyContainer>
          <H4>User info - failed</H4>
          <Alert
            buttons={[
              {
                callback: () => {
                  resetGet();
                  userMeRestore();
                },
                label: "Try again",
                type: BUTTON_PRIMARY,
              },
            ]}
            message={errorMessage || `There was an error loading your info`}
            testId="setting"
            title="Oops"
            type={ALERT_ERROR}
          />
        </ComfyContainer>
      );
    }

    if (pending) {
      return <Loading task="updatingInfo" />;
    }

    return (
      <Wrapper>
        <H4>Settings</H4>
        {user && (
          <EditSettings
            canUpdateUser={canUpdateUser}
            editUserRoles={editUserRoles}
            editUserStatus={editUserStatus}
            userDetails={user}
            ownedCompanies={ownedCompanies}
            ownedProperties={ownedProperties}
            onSubmit={this.handleSubmit.bind(this)}
            reset={resetGet}
          />
        )}
      </Wrapper>
    );
  }

  handleSubmit(updatedDetails: { [key: string]: any }) {
    const { user: updatedUser } = updatedDetails;
    this.setState({ updatedUser });
    const { userMeUpdate } = this.props;
    userMeUpdate(updatedUser);
  }

  componentDidMount() {
    const { users, userMeRestore, resetGet } = this.props;
    const { editUserStatus } = users;
    if (editUserStatus?.user) {
      resetGet();
    }
    userMeRestore();
  }
}

export default Settings;
